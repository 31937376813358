import React, { useState } from "react";
import Header from "./components/header";
import SwitchMenu from "./components/SwitchMenu";
import DetailsGrid from "./components/detailsGrid";
import grayPie from "../../../../Assets/Images/misc/GrayPie.svg";
import redDots from "../../../../Assets/Images/misc/RedDots.svg";
import redDotGradient from "../../../../Assets/Images/misc/redDotGradient.svg";
import redPie from "../../../../Assets/Images/misc/redPie.svg";
import redDotsTilted from "../../../../Assets/Images/misc/redDotsTilt.svg";

const WhatsInIt = () => {
  const [selectedOption, setSelectedOption] = useState("Passengers");
  return (
    <div className="h-auto flex flex-col mt-[40px] relative">
      {/* Images for Design */}
      <img
        src={grayPie}
        alt="Gray_Pie"
        className="absolute z-5 right-0 md:top-[50px] sm:w-[80px] md:w-[140px] w-[60px]"
      />
      <img
        src={redDots}
        alt="Red dots"
        className="absolute z-5 right-0 top-[27px] sm:top-[37px] md:top-[120px] sm:w-[35px] md:w-[65px] w-[28px]"
      />
      <img
        src={redDotGradient}
        alt="red_dot_gradient"
        className="absolute z-5 left-[5%] md:top-[3%] w-[40px] top-[100px] md:w-[70px]"
      />
      <img
        src={redPie}
        alt="red_Pie"
        className="absolute sm:hidden top-[98px] w-[40px] left-[5%] z-5"
      />
      <img
        src={redDotsTilted}
        alt="red_dots_tilt"
        className="absolute sm:hidden right-5 top-[780px] z-5"
      />
      <img
        src={redPie}
        alt="red_Pie"
        className="absolute z-5 sm:hidden right-0 top-[1150px] w-[80px]"
      />
      <img
        src={redDotGradient}
        alt="red_dot_gradient"
        className="absolute z-5 sm:hidden left-10 w-[40px] top-[1450px] "
      />
      <img
        src={redDotGradient}
        alt="red_dot_gradient"
        className="absolute z-5 sm:hidden right-10 w-[40px] top-[400px] "
      />
      {selectedOption === "Passengers" && (
        <img
          src={redDotGradient}
          alt="red_dot_gradient"
          className="absolute z-5 sm:hidden right-5 w-[40px] top-[2000px] "
        />
      )}
      <img
        src={redDotGradient}
        alt="red_dot_gradient"
        className="hidden lg:flex absolute z-5 right-[20%] top-[1050px] "
      />
      <img
        src={redDotGradient}
        alt="red_dot_gradient"
        className="sm:hidden absolute z-5 left-[10px] w-[40px] top-[2050px] "
      />
      <img
        src={redDotGradient}
        alt="red_dot_gradient"
        className="sm:hidden absolute z-5 left-[10px] w-[40px] top-[3080px] "
      />
      {/* Heading */}
      <Header />
      {/* Passenger coolie switch menu */}
      <SwitchMenu
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
      {/* Passengers and coolies facilities grid */}
      <DetailsGrid selectedOption={selectedOption} />
    </div>
  );
};

export default WhatsInIt;
