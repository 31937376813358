import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./NotAnOption.css";
import Img3 from "../../../../Assets/Images/misc/Ellipse 1.svg";
import Img4 from "../../../../Assets/Images/misc/Ellipse 2.svg";
import vbar from "../../../../Assets/Images/misc/Vector 1.svg";
import coolie from "../../../../Assets/Images/coolies/coolie.webp";


const Main = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleButtonClick = () => {
    setIsClicked(true);
  };

  useEffect(() => {
    if (isClicked) {
      // Reset the animation after a short delay
      const timeoutId = setTimeout(() => {
        setIsClicked(false);
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [isClicked]);
  return (
    <div className="flex md:flex-row flex-col mt-4">
      <div className="relative p-2 w-[100%] md:w-[70%] flex flex-col md:p-10 md:ml-5 text-center">
        <img
          src={Img3}
          alt="img"
          className="absolute top-[10%] w-[15vw] left-[6%]"
        />
        <img
          src={Img4}
          alt="img"
          className="absolute top-[40%] w-[10vw] left-[75%]"
        />
        <div
          className="m-2 md:text-[2.5vw] text-[4vw] w-[100%]"
          style={{ fontWeight: 400 }}
        >
          India&#8217;s Next Gen CoolieWale Services.
        </div>
        <div className="text-[5vw] md:text-[5vw]" style={{ fontWeight: 700 }}>
          <span className="text-[#364547]" style={{ fontFamily: 'SAMAN___' }}>  We Carry </span>
          <span className="text-[#EC1C24]" style={{ fontFamily: 'SAMAN___' }}>  More Than  </span>
          
        </div>
        <div> 
        </div>
        <div className="text-[5vw] md:text-[5vw]" style={{ fontWeight: 700 }}>
          <span className="text-[#364547]" style={{ fontFamily: 'SAMAN___' }}> Just Loads</span>
          <span className="text-[#EC1C24]" style={{ fontFamily: 'SAMAN___' }}>  We Carry Pride</span>
        </div>
        <div className="text-[2.5vw] md:text-[1.5vw] text-[#364547] mt-4 mb-8">
          Cooliewale is India&#8217;s first and fastest growing coolie services
          which digitalizes the relationship between coolies and passengers.
        </div>
        <div
          className="flex flex-row p-2 md:p-5 justify-evenly text-[3vw] md:text-[2vw] text-center items-center text-[#8F8F8F]"
          style={{
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: 50,
          }}
        >
          <div>Phone No.</div>
          <div>
            <img src={vbar} alt="barImg" />
          </div>
          <div>Location</div>
          <div>
            <img src={vbar} alt="barImg" />
          </div>
          <div>Pickup Date</div>
          <div
            className="pl-4 pr-4 cursor-pointer p-2 text-black"
            style={{
              boxShadow: isClicked ? 'none' : '0px 5px 2px #EC1C24',
              borderRadius: "50px",
              border: "2px solid black",
              fontWeight: "bold",
            }}
            onMouseDown={handleButtonClick}
          >
            <NavLink to="https://wa.me/919473200007?text=Hi">
              Book Now !
            </NavLink>
          </div>
        </div>
      </div>
      <div className="w-full md:w-[30%]  mt-4 flex text-center p-4 justify-center">
        <div className="rounded-full overflow-hidden ">
          <img
            src={coolie}
            alt="Coolie"
            className="w-full object-cover"
            style={{ borderRadius: "50%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
