import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UserReviewCard from "../../../../shared/Cards/UserReviewCard";
import Lovely from "../../../../../Assets/Images/users/Lovely_gupta.webp";
import Gaurav from "../../../../../Assets/Images/users/Gaurav.webp";
import Atul from "../../../../../Assets/Images/users/Atul.webp";
import Krishna from "../../../../../Assets/Images/users/Krishna.webp"

const Testimonials = () => {
  const [slidesToShow, setSlidesToShow] = useState(1);

  const updateSlidesToShow = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 1200) {
      setSlidesToShow(3);
    } else if (windowWidth >= 768) {
      setSlidesToShow(2);
    } else {
      setSlidesToShow(1);
    }
  };

  useEffect(() => {
    // Set initial slidesToShow value
    updateSlidesToShow();

    // Update slidesToShow on window resize
    window.addEventListener("resize", updateSlidesToShow);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSlidesToShow);
    };
  }, []);

  const reviews = [
    {
      id: 1,
      pic: Atul,
      name: "Atul",
      profession: "Frequent Traveler",
      review:
        "“CoolieWale made my railway journey a breeze! The platform delivered on its promise – a coolie at my service within minutes, hassle-free communication. My go-to for stress-free travel!”",
    },
    {
      id: 2,
      pic: Gaurav,
      name: "Gaurav",
      profession: "Business Professional",
      review:
        "“CoolieWale has transformed the way I approach railway travel. The quick response time, precise coordination, and multi-language support are game-changers. Finally, a service that aligns with the pace of business!”",
    },
    {
      id: 3,
      pic: Lovely,
      name: "Lovely",
      profession: "Solo Traveler",
      review:
        "“As a solo traveler, CoolieWale provided me with a sense of security and convenience. The platform's simplicity in choosing a coolie, allowed me to focus on my journey, worry-free.”",
    },
    {
      id: 4,
      pic: Krishna,
      name: "Krishna",
      profession: "Commuter",
      review:
        "“CoolieWale is a commuter's dream come true. No more waiting or negotiating; just input your details, and your coolie is ready and waiting. This platform has truly streamlined the assistance process at railway stations.”",
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="m-0 w-[90%] mx-auto mb-6 sm:mb-0">
      <div className="max-w-screen-xl mx-auto p-2 z-1 m-0">
        {/* <img src={redDots} alt="redDots" className="absolute right-0 " style={{zIndex:"-10"}}/> */}
        <Slider {...settings} f>
          {reviews.map((user) => (
            <div key={user.id}>
              <UserReviewCard user={user} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
