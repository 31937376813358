import React from 'react'
import Team from './team2'
import Navbar from '../shared/Navbar/navbar'
import Footer from '../shared/footer'

const TeamPage = () => {
  return (
    <div className="flex flex-col">
        <Navbar />
        <Team />
        <Footer />
    </div>
  )
}

export default TeamPage
