import React from "react";
import dottedRedCircle from "../../../Assets/Images/misc/dottedRedCircle.svg";

const Card1 = ({ id, title, description, image }) => {
  return (
    <div className="flex md:p-10 p-5 relative">
      <div className="absolute top-[2%] left-[6%] flex justify-center items-center">
        <div
          className="md:w-[90px] md:h-[90px] w-[80px] h-[80px] absolute"
          style={{
            backgroundImage: `url(${dottedRedCircle})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <div className="text-[#EC1C24] text-[45px]" style={{ fontWeight: 700 }}>
          {id}
        </div>
      </div>
      <div
        className="flex flex-col border justify-center items-center p-4"
        style={{
          boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.07)",
          borderRadius: 20,
        }}
      >
        <div className="mt-[40px] mb-3">
          <div className="w-[120px] h-[120px] rounded-full bg-gray-300 ">
            <img src={image} alt="User Pic" className="h-full  w-full" />
          </div>
        </div>
        <div className="flex flex-col text-center mb-4">
          <div
            className="md:text-[40px] text-[30px] "
            style={{ fontWeight: 600 }}
          >
            {title}
          </div>
          <div
            className="mb-8"
            style={{
              color: "#58595B",
              fontSize: 20,
              fontWeight: "400",
              wordWrap: "break-word",
              // textAlign: "justify",
            }}
          >
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card1;
