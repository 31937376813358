import React from "react";
import "./team2.css";
import TeamCard2 from "./components/teamCard2";
import StyledUnderline from "../../Assets/Images/misc/styledUnderline.svg"
import leftGrayPie from "../../Assets/Images/misc/leftGrayPie.svg"

import mrinal_pic from "../../Assets/Images/team/mrinal_pic.webp";
import Utsav_pic from "../../Assets/Images/team/utsav.webp";
import Risabh_pic from "../../Assets/Images/team/rishabh.webp";
import Priyanshu_pic from "../../Assets/Images/team/priyanshu.webp";
import dummy_user  from "../../Assets/Images/dummy_users/avatar-3637425_1280.webp";
import smart_user  from "../../Assets/Images/dummy_users/avatar-3637561_1280.webp";
import madam_user  from "../../Assets/Images/dummy_users/icons-842855_640.webp";
import siddh from "../../Assets/Images/team/15411956.webp";

const managementMembers = [
  {
    name: "Mr. Utsav Kumar",
    image: Utsav_pic,
    role: "Director and CEO",
    description: `Mr. Utsav Kumar holds the position of Director  & CEO at PhoodCry Pvt Ltd, operating under the brand name "CoolieWale." As the Director  & CEO, Mr. Kumar is responsible for overseeing the overall strategic direction, operational management, and business development of the company. His leadership ensures that CoolieWale maintains its commitment to innovation and customer satisfaction, driving the company's growth and market presence in the competitive logistics industry. Under his guidance, PhoodCry Pvt Ltd continues to enhance its service offerings, streamline operations, and expand its reach, solidifying its reputation as a reliable and efficient brand in the logistics sector.`,
  },

  {
    name: "Mr. Rishabh Singh",
    role: "COO",
    image: Risabh_pic,
    description: `Mr. Rishabh Singh is the Chief Operating Officer (COO) at M/s PhoodCry Pvt Ltd, known for its brand "CoolieWale". He holds a Bachelor of Engineering in Artificial Intelligence and Data Science from D.Y. Patil College of Engineering, Akurdi, Pune. Rishabh's expertise spans programming languages like Python, C, and C++, and frameworks such as ReactJS and NodeJS, alongside proficiency in databases like MongoDB and MySQL. His career includes teaching roles at ByteXL and Barclays, where he significantly boosted student performance. Key projects he led include Remote Lab Management Software, Adaptive SignalSense, and the award-winning CoolieWale platform. His achievements include being the First Year Department Topper and winning the Best Extracurricular Student award. He also holds certifications from IBM and Google.`,
  },
  {
    name: "Mr. Sumit ",
    role: "CTO ",
    image:dummy_user,
    description: `As the Chief Technology (CTO) at M/s PhoodCry Pvt Ltd, operating under the brand name "CoolieWale," Mr. Sumit brings a wealth of expertise in technology  to the management team. With a robust background in artificial intelligence, machine learning, and data science, he excels at transforming complex data into actionable insights and developing intelligent solutions that drive innovation. His extensive experience as a full stack and app developer ensures that CoolieWale’s digital infrastructure is both cutting-edge and reliable. ` },
  {
    name: "Priyanshu Singh",
    role: "Head-Technical",
    image: Priyanshu_pic,
    description: `As the Head-Technical at M/s PhoodCry Pvt Ltd, under the brand name "CoolieWale," Mr. Priyanshu Singh plays a pivotal role in driving the company's technological advancements. With a solid academic foundation in Metallurgical and Materials Engineering from the National Institute of Technology, Jamshedpur, and an impressive track record of professional achievements, Priyanshu brings a wealth of expertise to the team. His experience includes integrating advanced backend solutions, such as Kafka and Redis, to enhance system performance and reduce operational costs. Priyanshu has a proven ability to develop and deploy complex projects, including an online judge platform and a Facebook DM helpdesk, demonstrating his proficiency in various technologies such as Node.js, React.js, Docker, and AWS. His technical acumen, combined with his innovative approach to solving challenges, ensures that CoolieWale remains at the forefront of technological excellence.
    `,
  },
  {
    name: "Mr. Mrinal Varshney",
    role: "Head-Data Analytics",
    image: mrinal_pic,
    description: `As the Head-Data Analytics at M/s PhoodCry Pvt Ltd, operating under the brand name "CoolieWale," Mr. Mrinal Varshney leverages his extensive background in computer science and engineering to drive data-driven decision-making processes. A distinguished undergraduate from Motilal Nehru National Institute of Technology, Allahabad, Mrinal has demonstrated exceptional proficiency in data analysis, web development, and backend systems. His experience includes playing a crucial role in developing CoolieWale’s web application, showcasing his skills in technologies such as React.js, Node.js, and Tailwind CSS. Mrinal’s projects, like Create Flow and Set-Off, highlight his ability to integrate complex functionalities and enhance user experiences through real-time and responsive design. His technical acumen, combined with a keen interest in system design and data structures, ensures that CoolieWale's data analytics strategies are both innovative and effective, driving the brand towards sustained growth and excellence.`,
  },
];

const advisoryMembers = [
  {
    name: "Mr. Vivek Kumar",
    role: "Lead Consultant ",
    image:"",
    description: `We are pleased to announce that Mr. Vivek Kumar has joined PhoodCry Pvt Ltd under our brand name "CoolieWale" as the Lead Consultant for Business Development. With an extensive background in management consulting, business strategy, and marketing, Vivek brings a wealth of experience and expertise to our team. Vivek's role at CoolieWale will focus on driving business development initiatives, crafting strategic partnerships, and exploring new market opportunities to propel our brand to new heights. His vast experience and strategic insights will be invaluable as we continue to expand our services and enhance our market presence. We are confident that his expertise and leadership will significantly contribute to the continued success and growth of CoolieWale.`,
  },
  {
    name: "Ms. Naina Singh",
    role: "Lead Consultant",
    image:"",
    description: `Welcome to the Advisory Team of CoolieWale, the innovative brand under M/s PhoodCry Pvt Ltd. Our team is comprised of dedicated professionals who bring a wealth of experience and expertise to our organization. We are proud to introduce our esteemed Lead Consultant - Human Resource, Ms. Naina Singh. Ms. Naina Singh is a distinguished professional with an extensive background in human resource management, education, and nutrition. She holds a diverse range of qualifications and has made significant contributions in her field. Ms. Singh aims to leverage her comprehensive academic background and diverse professional experiences to drive organizational growth and success. She has a strong passion for continuous learning and is dedicated to promoting holistic well-being in both educational and organizational contexts. Ms. Naina Singh's multifaceted expertise and dedication make her an invaluable asset to the CoolieWale brand and the entire M/s PhoodCry Pvt Ltd team. Her leadership in Human Resources is pivotal to our mission of fostering a thriving, well-rounded, and innovative organizational culture.
    `,
  },
];

function Team() {
  return (
    <div className="flex flex-col items-center">
      <section>
            <div className="relative w-full flex mt-[20px] md:mt-[40px] flex-col justify-center text-center items-center sm:text-[28px] text-[24px] font-semibold">
      <img src={leftGrayPie} alt="leftGrayPie" className="absolute top-0 left-0 w-[7vw]" />
      <div className="md:text-[1.5em]"> Meet our Team</div>
      <div className="w-[20px] sm:w-auto">
        <img src={StyledUnderline} alt="underline" />
      </div>
    </div>
        {/* Management Team */}
        <div className="m-[2%] text-4xl font-bold text-white team-title-background  oval-md p-2">
          Management Team
        </div>

        <div className="flex flex-wrap gap-[5%] justify-center">
          {managementMembers.map((member) => {
            return (
              <>
                <TeamCard2
                  image={member.image } 
                  name={member.name}
                  role={member.role}
                  description={member.description}
                />
              </>
            );
          })}
        </div>
        {/* Advisory Team */}
        <div className="m-[2%] text-3xl font-bold text-white team-title-background rounded-md p-2" >
          Advisory Team
        </div>
        <div className="flex flex-wrap gap-[5%] justify-center">
          {advisoryMembers.map((member) => {
            return (
              <>
                <TeamCard2
                
                  name={member.name}
                  role={member.role}
                  description={member.description}
                />
              </>
            );
          })}
        </div>
      </section>
    </div>
  );
}


export default Team;
