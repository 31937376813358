import React from "react";
import FeatureCard from "../../../../shared/Cards/FeatureCards";
import offerings from "../../../../../Assets/Data/offerings";

const DetailsGrid = ({ selectedOption }) => {
  return (
    <div className="flex flex-wrap ml-[8%] mr-[8%] sm:ml-[2%] sm:mr-[2%] mt-[50px] justify-center">
      {selectedOption === "Passengers"
        ? offerings.Passengers.map((service) => (
            <FeatureCard
              key={service.id} // don't forget to add a unique key if these elements are part of a list
              card={{
                title: service.title,
                description: service.description,
                image: service.image
              }}
            />
          ))
        : offerings.Coolies.map((service) => (
            <FeatureCard
              key={service.id} // don't forget to add a unique key if these elements are part of a list
              card={{
                title: service.title,
                description: service.description,
                image: service.image
              }}
            />
          ))}
    </div>
  );
};

export default DetailsGrid;
