import React, { useState } from "react";

const LanguageSelector = () => {
  const [currentLanguage, setCurrentLanguage] = useState("English");

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setCurrentLanguage(selectedLanguage);

  };
//  removed hindi as it doesnt exist
  const languages = ["English"];

  return (
    <div className="flex items-center space-x-4">
      <div className="relative">
        <select
          className="appearance-none bg-transparent cursor-pointer border-none p-3 border text-black font-semibold px-4 pr-8 rounded leading-tight focus:outline-none focus:border-transparent focus:shadow-outline-blue"
          onChange={handleLanguageChange}
          value={currentLanguage}
        >
          {languages.map((language) => {
            return <option value={language}>{language}</option>;
          })}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M5 7l5 5 5-5z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
