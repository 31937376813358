import React from "react";
import StyledUnderline from "../../../Assets/Images/misc/styledUnderline.svg";
import Card1 from "./card1";
import leftGrayPie from "../../../Assets/Images/misc/leftGrayPie.svg";
import redDots from "../../../Assets/Images/misc/RedDots.svg";
import redDot from "../../../Assets/Images/misc/redDotGradient.svg";
import ratingSystem from "../../../Assets/Images/misc/ratingSystem.png";
import customerSupport from "../../../Assets/Images/misc/customerSupport.png";
import insurance from "../../../Assets/Images/misc/insurance.png";
import cooliesSafety from "../../../Assets/Images/coolies/coolie_safety.webp"
import Security from "../../../Assets/Images/feature/police-8749316_640.webp"
const CoolieSafetySection = () => {
  return (
    <div className="mt-[60px]">
      <div className="relative flex md:flex-row flex-col items-center flex-wrap justify-evenly">
        <div className="relative text-[48px] md:w-1/3" style={{ fontWeight: 600 }}>
          <div className="relative w-full flex mt-[40px] md:mt-[80px] flex-col justify-center text-center items-center text-[30px] lg:text-[56px] md:text-[40px] font-semibold">
            <div>Coolie&#8217;s Safety & Security :</div>
            <div className="w-[15vw] ">
              <img src={StyledUnderline} alt="underline" />
            </div>
          </div>
          <img
            src={redDot}
            alt="dot"
            className="absolute bottom-0 left-0 w-[4vw]"
          />
        </div>
        <img
            src={redDot}
            alt="dot"
            className="absolute top-0 right-[2%] w-[4vw]"
          />
        <div
          className="md:w-[40%] lg:h-[400px] h-[350px] w-[80%] mt-[20px] md:mt-0"
          style={{
            fontWeight: 600,
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.07)",
            border: "1px #E2E8F0 solid",
            borderRadius: 20,
          }}
        >
          <img src={Security} alt="Security" className="rounded-[20px] object-cover w-full h-full" />
        </div>
        <div className="flex md:flex-row flex-col justify-around mt-4">
          <div className="md:w-[45%] p-3">
            <Card1
              title="Two way rating system "
              description="Post the lift, both parties can give a rating to each other and any rating below 3 is flagged from CoolieWale’s end. CoolieWale reaches out to them in 10 minutes to address their concern."
              id="1"
              image={ratingSystem}
            />
          </div>
          <div className="md:w-[45%] p-3">
            <Card1
              id="2"
              title="Customer Support"
              description="Coolies can report any kind of issues to CoolieWale through 24*7 support feature on platform post and during the lift."
              image={customerSupport}
            />
          </div>
        </div>
        <div className="flex md:flex-row flex-col justify-around mt-4 relative w-full">
          <img
            src={leftGrayPie}
            alt="leftGrayPie"
            className="hidden md:flex absolute top-[20%] left-0 w-[7vw]"
          />
          <img
            src={redDots}
            alt="redDots"
            className="hidden md:flex absolute top-[50%] right-[5%] w-[6vw]"
          />
          <div className=" p-3">
            <Card1
              title="Insurance"
              description=" Accidental & medical facilities are provided to coolies when needed."
              id="3"
              image={insurance}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoolieSafetySection;
