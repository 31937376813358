import React from "react";


const TransparentButton = ({ title }) => {
  return (
    <div
      className="bg-transparent p-4 rounded-[20px] hover:bg-slate-200 cursor-pointer"
      style={{
        color: "black",
        fontWeight: "600",
        wordWrap: "break-word",
      }}
    >
      {title}
    </div>
  );
};

export default TransparentButton;
