import React, { useState } from "react";
import Header from "./Components/header";
import Icons from "./Components/icons";
import redDot from "../../../../Assets/Images/misc/redDotGradient.svg";
import DigitalizationImg from "../../../../Assets/Images/misc/Digitalization.png";
import MobilityImg from "../../../../Assets/Images/misc/mobility.png";
import ServicesImg from "../../../../Assets/Images/misc/services.png";

const Objectives = () => {
  const defaultStyle = {
    transition: "transform 0.3s ease-in-out",
    transform: "scale(1)",
  };
  // UseState for maintaining the state of the icon when hovered
  const [icon1Style, setIconStyle] = useState(defaultStyle);
  const [icon2Style, setIcon2Style] = useState(defaultStyle);
  const [icon3Style, setIcon3Style] = useState(defaultStyle);

  const iconHoverStyle = {
    transform: "scale(1.1) translate(0px, -10px)",
  };
  return (
    <div>
      <Header />
      <div className="relative flex md:flex-row flex-wrap flex-col md:justify-evenly mt-[30px] md:items-start items-center">
        <img
          src={redDot}
          alt="img"
          className="absolute right-[5%] w-[4vw] top-0"
        />
        <div
          className="flex justify-center items-center mb-2 mt-2 cursor-pointer"
          style={icon1Style}
          onMouseOver={() => {
            setIconStyle(iconHoverStyle);
          }}
          onMouseOut={() => {
            setIconStyle(defaultStyle);
          }}
        >
          <Icons
            title="Mobility"
            description="CoolieWale is here to make your lift easier without any commission."
            image={MobilityImg}
          />
        </div>
        <div
          className="flex justify-center items-center mb-2 cursor-pointer"
          style={icon2Style}
          onMouseOver={() => {
            setIcon2Style(iconHoverStyle);
          }}
          onMouseOut={() => {
            setIcon2Style(defaultStyle);
          }}
        >
          <Icons
            title="Digitalization"
            description="CoolieWale where passenger meets Coolies digitally."
            image={DigitalizationImg}
          />
        </div>

        <div
          className="flex justify-center items-center mb-2 cursor-pointer"
          style={icon3Style}
          onMouseOver={() => {
            setIcon3Style(iconHoverStyle);
          }}
          onMouseOut={() => {
            setIcon3Style(defaultStyle);
          }}
        >
          <Icons
            title="Services"
            description="Trusted Coolies for safer lifts at a fair price."
            image={ServicesImg}
          />
        </div>
      </div>
    </div>
  );
};

export default Objectives;
