import React from "react";

const Header = () => {
  return (
      <div
        className="h-auto justify-between text-center sm:text-[56px] text-[24px] text-black"
        style={{ fontWeight: "700", wordWrap: "break-word" }}
      >
        What&#8217;s in it for u
      </div>
  );
};

export default Header;
