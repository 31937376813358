import React from "react";
import Navbar from "../shared/Navbar/navbar";
import Footer from "../shared/footer"; // Corrected import name to match case sensitivity
import BookingAppDescription from "./BookingAppDescription";
import CoolieAppDescription from "./CoolieAppDescription";
import StyledUnderline from "../../Assets/Images/misc/styledUnderline.svg"
import leftGrayPie from "../../Assets/Images/misc/leftGrayPie.svg"  


const AppDescription = () => {
  return (
    <div>
      <Navbar />
     <div className="relative w-full flex mt-[20px] md:mt-[40px] flex-col justify-center text-center items-center sm:text-[28px] text-[24px] font-semibold">
      <img src={leftGrayPie} alt="leftGrayPie" className="absolute top-0 left-0 w-[7vw]" />
      <div className="md:text-[1.5em]"> App Description</div>
      <div className="w-[20px] sm:w-auto">
        <img src={StyledUnderline} alt="underline" />
      </div>
    </div>
     
 {/* Booking App Description */}
      <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-lg">
<div className="bg-[#EC1C24] mb-8 font-bold border-b-4 border-ec1c24 pb-2 rounded-full text-center">
  <h2 className="text-3xl mb-4">Booking App Description</h2>
        <BookingAppDescription />
        </div>
</div>
     


      {/* Navigation Section */}
      <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-lg mt-8">
        <h3 className="text-xl font-bold mb-4">Navigation</h3>
        <p className="text-lg">
          <strong>Jump to:</strong>{" "}
          <a href="#booking" className="text-blue-500 hover:underline">
            Booking App Description
          </a>{" "}
          |{" "}
          <a href="#coolie" className="text-blue-500 hover:underline">
            Coolie App Description
          </a>
        </p>
      </div>

      {/* Coolie App Description */}

      <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-lg">
<div className="bg-[#EC1C24] mb-8 font-bold border-b-4 border-ec1c24 pb-2 rounded-full text-center">
  <h2 className="text-3xl mb-4">Coolie App Description</h2>
            <CoolieAppDescription />
        </div>
</div>
      <Footer />
    </div>
  );
};

export default AppDescription;
