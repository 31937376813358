import React from "react";

const Card = ({ image, description, overlayColor }) => {
  return (
    <div className="rounded-lg overflow-hidden relative">
      <div
        className={`absolute flex bottom-0 left-0 right-0 p-4 md:h-[206px] rounded-b-[40px]  bg-opacity-50 text-white text-center items-center`}
        style={
          overlayColor === "black"
            ? { backgroundColor: "rgba(0, 0, 0, 0.5)" }
            : { backgroundColor: "#EC1C2480" }
        }
      >
        <p className="text-[20px] md:text-[35px] font-semibold">
          {description}
        </p>
      </div>
      <img
        src={image}
        alt="Card Image"
        className="w-full h-full object-cover rounded-t-lg"
      />
    </div>
  );
};

export default Card;
