import React from "react";
import StyledUnderline from "../../../Assets/Images/misc/styledUnderline.svg";
import Card1 from "./card1";
import Card2 from "./card2";
import redDot from "../../../Assets/Images/misc/redDotGradient.svg";
import grayPie from "../../../Assets/Images/misc/GrayPie.svg";
import liftSharing from "../../../Assets/Images/misc/liftSharing.png";
import informationMasking from "../../../Assets/Images/misc/informationMasking.png";
import insurance from "../../../Assets/Images/misc/insurance.png";
import customerSupport from "../../../Assets/Images/misc/customerSupport.png";
import cooliesInformation from "../../../Assets/Images/misc/coolieInfo.png";
import Safety from "../../../Assets/Images/feature/technology-6701509_640.webp";
import Security from "../../../Assets/Images/feature/police-8749316_640.webp";
import Coolie_badge from "../../../Assets/Images/coolies/_9f83681a-75ad-11e5-9864-f322a89f42cf.webp";
const SafetySection = () => {
  const coolieData = [
    "Coolie name : Ramlal Choubey",
    "Coolie Badge no. : 23",
    "Past lift record : 30",
    "Past star ratings : 4.5",
  ];
  return (
    <div className="mt-[60px]">
      <div className="flex md:flex-row flex-col items-center flex-wrap justify-evenly">
        <div
          className="relative text-[48px] md:w-1/3"
          style={{ fontWeight: 600 }}
        >
          <div className=" w-full flex mt-[40px] md:mt-[80px] flex-col justify-center text-center items-center text-[30px] lg:text-[56px] md:text-[40px] font-semibold">
            <div>Passengers Safety & Security </div>
            <div className="w-[15vw] ">
              <img src={StyledUnderline} alt="underline" />
            </div>
          </div>
        </div>
        <img
          src={redDot}
          alt="dot"
          className="absolute bottom-0 left-[10%] w-[4vw]"
        />

        <div
          className="md:w-[40%] lg:h-[400px] h-[350px] w-[80%] mt-[20px] md:mt-0"
          style={{
            fontWeight: 600,
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.07)",
            border: "1px #E2E8F0 solid",
            borderRadius: 20,
            overflow: "hidden", // Ensure overflow is hidden to contain the image
          }}
        >
          <img
            src={Safety}
            alt="passengers safety security"
            className="object-cover w-full h-full"
            style={{ borderRadius: "20px"}}
          />
        </div>

        <div className="flex md:flex-row flex-col justify-around mt-4">
          <div className="md:w-[45%] p-3">
            <Card1
              title="Lift Sharing "
              description="Passengers can share their lift status with their emergency contacts on CoolieWale platform right after their lift starts."
              id="1"
              image={liftSharing}
            />
          </div>
          <div className="md:w-[45%] p-3">
            <Card1
              id="2"
              title="Information Masking "
              description="Passengers contact number is masked to protect their privacy and their interaction with the coolie happens only through platform encryption."
              image={informationMasking}
            />
          </div>
        </div>
        <div className="relative w-full flex justify-center">
          <img
            src={grayPie}
            alt="pie"
            className="absolute right-0 hidden md:flex w-[7vw]"
          />
          <Card2
            id="3"
            title="Coolies Information "
            list={coolieData}
            description="Passengers can share their lift status with their emergency contacts on CoolieWale platform right after their lift starts."
            image={Coolie_badge}
          />
        </div>
        <div className="flex md:flex-row flex-col justify-around mt-4">
          <div className="md:w-[45%] p-3">
            <Card1
              title="Customer Support "
              description=" Passengers can report any kind of issues to CoolieWale through 24*7 support feature on platform post and during the lift.."
              id="4"
              image={customerSupport}
            />
          </div>
          <div className="md:w-[45%] p-3">
            <Card1
              id="5"
              title="Insurance "
              description="Insurance can be claimed for damage/lost items that occurs during lift.."
              image={insurance}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafetySection;
