import React from "react";

const icons = ({ title, description, image }) => {
  return (
    <div className=" w-[70%]  md:max-w-[300px] flex flex-col  items-center text-center mb-10">
      <div
        className="h-[130px]  w-[130px] mb-8 flex justify-center items-center text-center bg-gray-200 "
        style={{ background: "rgba(236, 28, 36, 0.08)" }}
      >
        <img src={image} alt="img" className="object-fit w-full"/>
      </div>
      <div >
        <div className="pr-7 md:text-[2vw] sm:text-[4vw] text-[5.5vw]" style={{fontWeight:500}}>{title}</div>
        <div className="md:text-[1.5vw] sm:text-[3vw] text-[3.5vw]">{description}</div>
      </div>
    </div>
  );
};

export default icons;
