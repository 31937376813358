import React from "react";
import "./safety.css";
import ImageCollection from "../../../../Assets/Images/coolies/ImageCollection.webp";
import { useNavigate } from "react-router-dom";

const SafetyForAll = () => {
  const navigate = useNavigate();
  const scrollToTopAndNavigate = (path) => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    navigate(path); // Navigate to the specified path
  };
  return (
    <div className="flex md:flex-row flex-col-reverse justify-center items-center mt-[40px]">
      <div className="md:w-[45%] mt-5 md:mt-0">
        <img src={ImageCollection} alt="logo" className="" />
      </div>
      <div className="md:w-1/2 flex flex-col justify-center md:text-left text-center w-full">
        <div className="sm:text-[56px] font-bold text-[24px]">
          Safety for all
        </div>
        <div className="text-[#475569] text-[16px]">
          At Cooliewale, the well being of our passengers is above everything
          else. We are constantly in pursuit of enhancing our safety measures to
          ensure every coolies lift is a pleasant and comfortable experience.
        </div>
        <div className="text-[#EC1C24] mt-5 font-bold md:text-[20px] text-[15px]">
          <span
            className="cursor-pointer"
            onClick={() => {
              scrollToTopAndNavigate("/safety");
            }}
          >
            Know More{" "}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SafetyForAll;
