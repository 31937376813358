import fairFare from "../Images/misc/Fair_fare.png";
import liftControl from "../Images/misc/liftControl.png";
import fastService from "../Images/misc/EfficientService.png";
import digitalPayment from "../Images/misc/directPayments.png";
import customerSupport from "../Images/misc/Multiple_payment.png";
import languageSuppport from "../Images/misc/multiLanguageSupport.png";
import ZeroPressureLifts from "../Images/misc/ZeroPressureLifts.png";
import directPayments from "../Images/misc/directPayments.png";
import digitalization from "../Images/misc/Digitalization.png";
import insurance from "../Images/misc/insurance.png";

const offerings = {
  Passengers: [
    {
      title: "Fair Fares",
      description:
        "People agree on a win-win price for both sides. Fair pay always inspires quality work.",
      image: fairFare,
    },
    {
      title: "Lift control",
      description:
        "Our total transparency puts people back in control of their journeys. Passengers and Coolies are free to choose each other, and the types of lifts, for more comfortable lifts.",
      image: liftControl,
    },
    {
      title: "Fast and Efficient Lifting service",
      description:
        "People contact each other directly with no middleman, and discuss all the details quickly.",
      image: fastService,
    },
    {
      title: "Digital Payment",
      description:
        "In our platform we will accept both cash and digital payment.",
      image: digitalPayment,
    },
    {
      title: "Customer support(24*7)",
      description:
        "Passengers can report any kind of issues to CoolieWale through 24*7 support feature on platform post and during the lifts.",
      image: customerSupport,
    },
    {
      title: "Multi language support",
      description:
        "Passengers and Coolies can effectively communicate in their preferred language in our platform.",
      image: languageSuppport ,
    },
  ],
  Coolies: [
    {
      title: "Zero Pressure Lifts",
      description:
        "Gives a Coolie more room in a day without hampering their daily activities. You can decide on when to go on the next lift.",
      image: ZeroPressureLifts ,
    },
    {
      title: "Direct Payments",
      description:
        "Passengers pay Coolies directly. This allows us to reduce costs and maintain lower fares.",
      image: directPayments,
    },
    {
      title: "Customer support(24*7)",
      description:
        "Coolies can report any kind of issues to CoolieWale through 24*7 support feature on platform post and during the lift.",
      image:  customerSupport ,
    },
    {
      title: "Digitalization",
      description: "Get notified wherever they are.",
      image: digitalization ,
    },
    {
      title: "Insurance",
      description:
        "Accidental & medical facilities are provided to coolies when needed.",
      image:  insurance ,
    },
  ],
};

export default offerings;
