import React from "react";

const SwitchMenu = ({ selectedOption, setSelectedOption }) => {
  return (
    <div className="flex justify-center items-center mt-[10px] sm:mt-[40px]">
      <div className="flex flex-row justify-between m-2">
        {/* Passenger option */}
        <div
          className="cursor-pointer flex justify-center items-center sm:p-2 w-[130px] text-[12px] sm:text-xl sm:w-[230px] hover:shadow-2xl ml-4 rounded-tl-[25px] rounded-bl-[5px]"
          style={
            selectedOption === "Passengers"
              ? {
                  color: "white",
                  backgroundColor: "#EC1C24",
                  fontWeight: "400",
                }
              : {
                  color: "black",
                  backgroundColor: "transparent",
                  fontWeight: "400",
                  border: "2px rgba(0, 0, 0, 0.10) solid",
                }
          }
          onClick={() => {
            setSelectedOption("Passengers");
          }}
        >
          Passengers
        </div>
        {/* Coolie options */}
        <div
          className="cursor-pointer flex justify-center items-center sm:p-2 w-[130px] text-[12px] sm:text-xl sm:w-[230px] hover:shadow-2xl ml-4 rounded-br-[25px] rounded-tr-[5px]"
          style={
            selectedOption === "Coolies"
              ? {
                  color: "white",
                  backgroundColor: "#EC1C24",
                  fontWeight: "400",
                }
              : {
                  color: "black",
                  backgroundColor: "transparent",
                  fontWeight: "400",
                  border: "2px rgba(0, 0, 0, 0.10) solid",
                }
          }
          onClick={() => {
            setSelectedOption("Coolies");
          }}
        >
          Coolies
        </div>
      </div>
    </div>
  );
};

export default SwitchMenu;
