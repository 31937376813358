import React, { useState } from "react";

const FeatureCards = ({ card }) => {
  const defaultStyle = {
    transition: "transform 0.3s ease-in-out",
    transform: "scale(1)",
  };
  // UseState for maintaining the state of the icon when hovered
  const [iconStyle, setIconStyle] = useState(defaultStyle);

  const iconHoverStyle = {
    transform: "scale(1.1) translate(0px, -10px)",
  };
  return (
    <div
      className="flex flex-col bg-transparent items-center p-4 mb-10 md:w-1/3 sm:w-[40%] cursor-pointer"
      style={iconStyle}
      onMouseOver={() => setIconStyle(iconHoverStyle)}
      onMouseOut={() => setIconStyle(defaultStyle)}
    >
      {/* Image section */}
      <div className="h-[80px] w-[80px] sm:w-[120px] sm:h-[120px] md:w-[150px] md:h-[150px] mb-2 ">
        <div className="h-[80px] w-[80px] sm:w-[100px] sm:h-[100px] md:w-[150px] md:h-[150px] mb-2">
          <img
            src={card.image}
            alt="img"
            className="h-full w-full object-fill"
          />
        </div>
      </div>
      {/* Title */}
      <div className="mb-5 font-semibold text-[24px] flex justify-center text-center pr-2 ">
        {card.title}
      </div>
      {/* Description */}
      <div className="text-[#475569] text-[15px] md:text-[18px] flex justify-center text-center">
        {card.description}
      </div>
    </div>
  );
};

export default FeatureCards;
