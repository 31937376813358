import React from "react";

const VisionAndMissionCard = ({ title, description,image }) => {
  return (
    <div
      className="flex flex-col max-w-[500px] border rounded-[20px] m-4"
      style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)" }}
    >
      <div className="md:h-[350px] sm:[300px] h-[280px] border rounded-tl-[20px] rounded-tr-[20px] bg-black">
        <img src={image} alt="img" className="w-[80%] h-full object-fit mx-auto"/>
      </div>
      <div className="p-5">
        <div className="lg:text-[40px] md:text-[35px] text-[24px]" style={{ fontWeight: 650 }}>
          {title}
        </div>
        <div className="lg:text-[20px] md:text-[18px] text-[16px] text-[#464646]">{description}</div>
      </div>
    </div>
  );
};

export default VisionAndMissionCard;
