import React from "react";
import Header from "./components/header";
import SupportDetails from "./components/SupportDetails";

const PickupSection = () => {
  return (
    <div>
      {/* Header */}
      <Header />
      {/* Support by coolies details */}
      <SupportDetails />
    </div>
  );
};

export default PickupSection;
