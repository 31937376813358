import React from "react";
import Navbar from "../shared/Navbar/navbar";
import WhatsInIt from "./components/WhatsInIt/whatsInIt";
import PickupSection from "./components/PickUpSection/pickupSection";
import ReviewSection from "./components/passengersReviewSection/reviewSection";
import Objectives from "./components/Objectives/Objectives";
import NotAnOption from "./components/NotAnOption/main";
import SafetyForAll from "./components/SafetyForAll/safetyForAll";
import KnowUs from "./components/KnowUs/main";
import Footer from "../shared/footer";

const HomePage = () => {
  return (
    <div>
      <Navbar />
      <NotAnOption />
      <Objectives />
      <SafetyForAll/>
      <KnowUs/>  
      <WhatsInIt />
      <PickupSection />
      <ReviewSection />
      <Footer />
    </div>
  );
};

export default HomePage;
