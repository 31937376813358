import React from "react";

const BookingAppDescription = () => {
  return (
    <div>
 
      <div className="flex justify-center text-center bg-[#EC1C24] mt-2">
        <div className="text-white p-4 flex flex-col items-start">
          <h2 className="text-5xl font-bold mb-2 border-b-4 border-ec1c24 pb-2">
            CoolieWale App
          </h2>
          <p className="text-lg font-semibold">Phoodcry Catering Private Limited</p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-lg">
        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            CoolieWale: Effortless Coolie Booking for a Stress-Free Train Journey
          </h2>
          <p className="text-gray-700">
            Traveling by train in India is an exciting adventure, but navigating stations with luggage can be challenging. CoolieWale is here to simplify your train travel experience by offering a convenient and reliable platform for booking coolies (porters) directly from your smartphone. We understand the difficulties of managing luggage, especially for families, senior citizens, or individuals with special needs. CoolieWale empowers you to book coolie services quickly and easily, ensuring a smooth and stress-free train journey.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            Currently Serving Banaras Station (BSBS)
          </h2>
          <p className="text-gray-700">
            Please note that while we are working to expand our services to other stations across India, CoolieWale is currently operational only at Banaras Station (BSBS). We are committed to providing a seamless booking experience for all your train journeys originating or terminating at Banaras Station.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            What is CoolieWale?
          </h2>
          <p className="text-gray-700">
            CoolieWale is a passenger-centric mobile application that connects travelers with verified and trained coolies to assist with their luggage at Banaras Station (BSBS) in India. We understand the challenges of managing luggage, especially for families, senior citizens, or individuals with special needs. CoolieWale empowers you to book coolie services quickly and easily, ensuring a smooth and stress-free train journey at Banaras Station.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            Key Features and Benefits
          </h2>
          <ul className="list-disc pl-6">
            <li className="text-gray-700 mb-4">
              Simple and Secure Registration: Download the CoolieWale app from the Google Play Store and register with your phone number. A one-time verification code (OTP) ensures a secure signup process.
            </li>
            <li className="text-gray-700 mb-4">
              Flexible Booking Options: CoolieWale offers multiple ways to book a coolie based on your travel plans, specifically for Banaras Station (BSBS).
              <ul className="list-disc pl-6">
                <li>PNR Number: Enter your 10-digit PNR number to automatically retrieve your train journey details, including arrival station (which must be Banaras Station - BSBS) and timings.</li>
                <li>Train Number: If you don't have your PNR, simply enter the train number to find coolie services for your specific train arriving at Banaras Station (BSBS).</li>
                <li>Manual Station Selection: Since we currently serve only Banaras Station (BSBS), this option allows you to select the arrival date and time to book a coolie for any train journey arriving there.</li>
              </ul>
            </li>
            <li className="text-gray-700 mb-4">
              Specify Your Needs: Beyond luggage assistance, CoolieWale allows you to specify your requirements for your journey at Banaras Station (BSBS).
              <ul className="list-disc pl-6">
                <li>Number of Luggage Pieces: Indicate the number of suitcases, bags, or boxes you'll be traveling with to ensure the coolie is prepared to handle your luggage efficiently.</li>
                <li>Wheelchair or Stretcher Assistance: If you require special assistance due to mobility limitations, you can request a coolie equipped to handle wheelchairs or stretchers at Banaras Station (BSBS).</li>
              </ul>
            </li>
            <li className="text-gray-700 mb-4">
              Transparent Pricing: CoolieWale provides a clear fare estimate before you confirm your booking at Banaras Station (BSBS). The fare is calculated based on several factors, including distance, number of luggage pieces, and special assistance.
            </li>
            <li className="text-gray-700 mb-4">
              Seamless Payment: CoolieWale prioritizes a secure and convenient payment experience. Currently, we offer UPI (Unified Payments Interface) as our primary digital payment mode.
            </li>
            <li className="text-gray-700 mb-4">
              Real-Time Tracking (Coming Soon): In future versions of the CoolieWale app, we plan to integrate a real-time tracking feature, allowing you to track your coolie's location within Banaras Station (BSBS).
            </li>
            <li className="text-gray-700">
              Customer Reviews and Ratings (Coming Soon): Future versions of the app will include a customer review and rating system.
            </li>
          </ul>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            Benefits for Passengers
          </h2>
          <ul className="list-disc pl-6">
            <li className="text-gray-700 mb-4">
              Convenience: Book coolie services anytime, anywhere using the user-friendly CoolieWale app. No more last-minute scrambling to find a reliable coolie at Banaras Station (BSBS).
            </li>
            <li className="text-gray-700 mb-4">
              Peace of Mind: Our verified coolies are trained and experienced in handling luggage and providing assistance, ensuring a stress-free travel experience at Banaras Station (BSBS).
            </li>
            <li className="text-gray-700">
              Accessibility: CoolieWale caters to passengers with diverse needs at Banaras Station (BSBS).
            </li>
          </ul>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            How to Use CoolieWale
          </h2>
          <ol className="list-decimal pl-6">
            <li className="text-gray-700 mb-4">Download the CoolieWale app from the Google Play Store.</li>
            <li className="text-gray-700 mb-4">Register using your phone number and complete the secure signup process with OTP verification.</li>
            <li className="text-gray-700 mb-4">Choose your booking method: PNR Number, Train Number, or Manual Station Selection.</li>
            <li className="text-gray-700 mb-4">Specify your requirements such as number of luggage pieces and any special assistance needed.</li>
            <li className="text-gray-700 mb-4">Review the fare estimate and confirm your booking.</li>
            <li className="text-gray-700 mb-4">Make payment securely via UPI (Unified Payments Interface).</li>
            <li className="text-gray-700">Receive confirmation and details of your booked coolie service.</li>
          </ol>
          <p className="text-gray-700 mt-4">
            <strong>Please Note:</strong> CoolieWale currently operates exclusively at Banaras Station (BSBS) and plans to expand to other stations in the future.
          </p>
        </div>
      </div>

     
    </div>
  );
};

export default BookingAppDescription;
