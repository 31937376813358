import React from "react";
import startUpLogo from "../../Assets/Images/startup_logo.svg";
import twitterLogo from "../../Assets/Images/companies/twitter.svg";
import instaLogo from "../../Assets/Images/companies/instagram.png";
import facebookLogo from "../../Assets/Images/companies/facebook.png";
import linkedInLogo from "../../Assets/Images/companies/linkedin.svg";
import playStoreLogo from "../../Assets/Images/companies/playstoreDownload.png";
import appleStoreLogo from "../../Assets/Images/companies/appleDownload.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const scrollToTopAndNavigate = (path) => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    navigate(path); // Navigate to the specified path
  };
  return (
    <div className="bg-black pb-4">
      <div className="bg-black sm:m-[60px] m-[10%] mb-0 mt-0 ">
        <div className=" flex-col hidden sm:flex sm:flex-row flex-wrap pt-10">
          {/* logo and Name */}
          <div className="w-1/4">
            <div className="w-[90px] h-[90px]">
              <img src={startUpLogo} alt="logo" className="w-full h-full" />
            </div>
            <div
              className="text-white font-extrabold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl"
              style={{ fontFamily: "SAMAN___" }}
            >
              <span className="text-[0.75em]">कुली</span>Wale

            </div>
            <div
              className="text-white font-bold text-xl "
              style={{ fontFamily: "SAMAN___" }}
            >
              PhoodCry Catering Private Limited
            </div>
                    <div
              className="text-white font-bold  "

            >
              CIN : U56102UP2023PTC183347
            </div>
          </div>

          {/* Contact us */}
          <div className="w-1/4">
            <div
              className="text-white text-[16px] mb-5"
              style={{ fontWeight: "bold" }}
            >
              Get in touch
            </div>
            <div className="text-white text-[16px] mb-5 flex items-center">
              {/* Phone */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="white"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="white"
                className="md:w-6 md:h-6 h-4 w-4 "
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                />
              </svg>
              <div className="ml-2 mr-2 md:text-[14px] text-[12px] lg:text-[18px]">
                <a href="tel:+91-8507992317">+91-8507992317</a>
              </div>
            </div>
            <div className="text-white text-[16px] mb-5 flex flex-row flex-wrap items-center">
              {/* Email */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="white"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="black"
                class="md:w-6 md:h-6 h-4 w-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                />
              </svg>
              <div className="ml-2 mr-2 md:text-[14px] text-[12px] lg:text-[18px]">
                <a href="mailto:utsav@cooliewale.in?cc=cooliewale@gmail.com">
                  {" "}
                  utsav@cooliewale.in{" "}
                </a>
              </div>
            </div>
          </div>
          {/* Quick Links */}
          <div className="w-1/4">
            <div
              className="text-white text-[16px] mb-5"
              style={{ fontWeight: "bold" }}
            >
              Quick Links
            </div>
            <div
              className="text-white text-[16px] mb-5 cursor-pointer hover:text-[#EC1C24]"
              onClick={() => {
                scrollToTopAndNavigate("/");
              }}
            >
              Home
            </div>
            <div
              className="text-white text-[16px] mb-5 cursor-pointer hover:text-[#EC1C24]"
              onClick={() => {
                scrollToTopAndNavigate("/aboutUs");
              }}
            >
              About us
            </div>
            <div
              className="text-white text-[16px] mb-5 cursor-pointer hover:text-[#EC1C24]"
              onClick={() => {
                scrollToTopAndNavigate("/safety");
              }}
            >
              Safety
            </div>
            <div
              className="text-white text-[16px] mb-5 cursor-pointer hover:text-[#EC1C24]"
              onClick={() => {
                scrollToTopAndNavigate("/team");
              }}
            >
              Team
            </div>
                     <div
              className="text-white text-[16px] mb-5 cursor-pointer hover:text-[#EC1C24]"
              onClick={() => {
                scrollToTopAndNavigate("/tAndc");
              }}
            >
              Terms & Condition
            </div>
                     <div
              className="text-white text-[16px] mb-5 cursor-pointer hover:text-[#EC1C24]"
              onClick={() => {
                scrollToTopAndNavigate("/privacyPolicy");
              }}
            >
              Privacy Policy
            </div>
                 <div
              className="text-white text-[16px] mb-5 cursor-pointer hover:text-[#EC1C24]"
              onClick={() => {
                scrollToTopAndNavigate("/CancellationPolicy");
              }}
            >
              Cancellation & Refund Policy
            </div>
            <div
              className="text-white text-[16px] mb-5 cursor-pointer hover:text-[#EC1C24]"
              onClick={() => {
                scrollToTopAndNavigate("/contactUs");
              }}
            >
              Contact us
            </div>
          </div>
          <div className="w-1/4">
            <div
              className="text-white text-[16px] mb-5"
              style={{ fontWeight: "bold" }}
            >
              Social Media Links
            </div>
            <div className="text-white text-[16px] mb-5">
              <div
                className="flex flex-row mb-5"
                style={{
                  transition: "transform 0.3s ease",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform = "translateY(-5px)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "translateY(0)")
                }
              >
                <img
                  src={twitterLogo}
                  alt="logo"
                  className="w-[25px] h-[25px]"
                />
                <div className="ml-2 text-[16px]">
                  <a href="https://x.com/cooliewale?t=wm3VWTYO009TebX1_TZo_w&s=08">
                    Twitter{" "}
                  </a>
                </div>
              </div>
              <div
                className="flex flex-row mb-5"
                style={{
                  transition: "transform 0.3s ease",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform = "translateY(-5px)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "translateY(0)")
                }
              >
                <img src={instaLogo} alt="logo" className="w-[25px] h-[25px]" />
                <div className="ml-2 text-[16px]">
                  <a href="https://www.instagram.com/cooliewaleofficial?igsh=MnVkc2o2OXBrcnht">
                    Instagram
                  </a>
                </div>
              </div>
              <div
                className="flex flex-row mb-5"
                style={{
                  transition: "transform 0.3s ease",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform = "translateY(-5px)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "translateY(0)")
                }
              >
                <img
                  src={linkedInLogo}
                  alt="logo"
                  className="w-[25px] h-[25px]"
                />
                <div className="ml-2 text-[16px]">
                  <a href="https://www.linkedin.com/company/cooliewale/?viewAsMember=true">
                    LinkedIn
                  </a>
                </div>
              </div>
              <div
                className="flex flex-row mb-5"
                style={{
                  transition: "transform 0.3s ease",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform = "translateY(-5px)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "translateY(0)")
                }
              >
                <img
                  src={facebookLogo}
                  alt="logo"
                  className="w-[25px] h-[25px]"
                />
                <div className="ml-2 text-[16px]">
                  <a href="https://www.facebook.com/CooliewaleOfficial">
                    Facebook
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* For mobile View */}
        <div className="pt-10 sm:hidden">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="w-[90px] h-[90px] mb-6">
                <img src={startUpLogo} alt="logo" className="w-full h-full" />
              </div>
              <div className="flex flex-col">
                <div
                  className="items-center flex text-white font-extrabold text-[32px]"
                  style={{ fontFamily: "SAMAN___" }}
                >
                  <span className="text-[0.75em]">कुली</span>Wale
                </div>
                <div
                  className="text-white font-bold text-lg items-center flex "
                  style={{ fontFamily: "SAMAN___" }}
                >
                  PhoodCry Catering Private Limited
                </div>
                       <div
                  className="text-white font-bold text-lg items-center flex "
                  style={{ fontFamily: "SAMAN___" }}
                >
                 CIN : U56102UP2023PTC183347
                </div>
                 CIN : U56102UP2023PTC183347
              </div>
            </div>
            <div className="flex flex-row justify-evenly w-full">
              <div className="flex flex-col w-[70%]">
                <div
                  className="text-white text-[16px] mb-5"
                  style={{ fontWeight: "bold" }}
                >
                  Quick Links
                </div>
                <div
                  className="text-white text-[16px] mb-5 cursor-pointer hover:text-[#EC1C24]"
                  onClick={() => {
                    scrollToTopAndNavigate("/");
                  }}
                >
                  Home
                </div>
                <div
                  className="text-white text-[16px] mb-5 cursor-pointer hover:text-[#EC1C24]"
                  onClick={() => {
                    scrollToTopAndNavigate("/aboutUs");
                  }}
                >
                  About us
                </div>
                <div
                  className="text-white text-[16px] mb-5 cursor-pointer hover:text-[#EC1C24]"
                  onClick={() => {
                    scrollToTopAndNavigate("/safety");
                  }}
                >
                  Safety
                </div>
                <div
                  className="text-white text-[16px] mb-5 cursor-pointer hover:text-[#EC1C24]"
                  onClick={() => {
                    scrollToTopAndNavigate("/tAndc");
                  }}
                >
                  Terms & Condition
                </div>
                     <div
                  className="text-white text-[16px] mb-5 cursor-pointer hover:text-[#EC1C24]"
                  onClick={() => {
                    scrollToTopAndNavigate("/privacyPolicy");
                  }}
                >
                  Privacy Policy
                </div>
                <div
                  className="text-white text-[16px] mb-5 cursor-pointer hover:text-[#EC1C24]"
                  onClick={() => {
                    scrollToTopAndNavigate("/contactUs");
                  }}
                >
                  Contact us
                </div>
              </div>
              <div className="flex flex-col w-[60%]">
                <div
                  className="text-white text-[16px] mb-5"
                  style={{ fontWeight: "bold" }}
                >
                  Get in touch
                </div>
                {/* <div className="text-white text-[16px] mb-5"> */}
                <div className="text-white text-[16px] mb-5 flex items-center">
                  {/* Phone */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="white"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="white"
                    className="md:w-6 md:h-6 sm:h-4 sm:w-4 h-3 w-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                    />
                  </svg>
                  <div className="ml-2 mr-2 md:text-[14px] text-[12px] lg:text-[18px]">
                   <a href="tel:+91-8507992317">+91-8507992317</a>
                  </div>
                  {/* </div> */}
                </div>
                <div className="text-white text-[16px] mb-5 flex flex-row flex-wrap items-center">
                  {/* Email */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="white"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="black"
                    class="md:w-6 md:h-6 sm:h-4 sm:w-4 h-3 w-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                    />
                  </svg>
                  <div className="ml-2 md:text-[14px] text-[10px] lg:text-[18px]">
                    utsav@cooliewale.in
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-col">
                <div
                  className="text-white text-[16px] mb-5"
                  style={{ fontWeight: "bold" }}
                >
                  Social Media Links
                </div>
                <div className="text-white text-[16px] mb-5">
                  <div className="flex flex-row mb-5">
                    <img
                      src={twitterLogo}
                      alt="logo"
                      className="w-[25px] h-[25px]"
                    />
                    <div className="ml-2 text-[16px]">
                      <a href="https://x.com/cooliewale?t=wm3VWTYO009TebX1_TZo_w&s=08">
                        Twitter
                      </a>
                    </div>
                  </div>
                  <div className="flex flex-row mb-5">
                    <img
                      src={instaLogo}
                      alt="logo"
                      className="w-[25px] h-[25px]"
                    />
                    <div className="ml-2 text-[16px]">
                      <a href="https://www.instagram.com/cooliewaleofficial?igsh=MnVkc2o2OXBrcnht">
                        instagram
                      </a>
                    </div>
                  </div>
                  <div className="flex flex-row mb-5">
                    <img
                      src={linkedInLogo}
                      alt="logo"
                      className="w-[25px] h-[25px]"
                    />
                    <div className="ml-2 text-[16px]">
                      <a href="https://www.linkedin.com/company/cooliewale/?viewAsMember=true">
                        LinkedIn
                      </a>
                    </div>
                  </div>
                  <div className="flex flex-row mb-5">
                    <img
                      src={facebookLogo}
                      alt="logo"
                      className="w-[25px] h-[25px]"
                    />
                    <div className="ml-2 text-[16px]">
                      <a href="https://www.facebook.com/CooliewaleOfficial">
                        facebook
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="border-[#A6A6A6] mb-8 hidden " />
            <div className="flex flex-row justify-center">
              <img
                src={appleStoreLogo}
                alt="Apple Store Logo"
                className="w-[100px] mb-3 mr-3"
              />
              <img
                src={playStoreLogo}
                alt="Play Store Logo"
                className="w-[100px] mb-3"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center text-center sm:hidden mt-3">
          <div className=" w-full flex justify-evenly text-center mb-2">
            <div className="text-[16px] text-white">
                PhoodCry Catering Private Limited. All rights reserved
            </div>
          </div>
          <div className=" w-full flex justify-evenly text-center">
            <div className="text-[16px] text-white mb-2">
              <span
                className="cursor:pointer"
                onClick={() => {
                  scrollToTopAndNavigate("/tandc");
                }}
              >
                Terms and Conditions
              </span>
            </div>
          </div>
          <div className=" w-full flex justify-evenly text-center ">
            <div className="text-[16px] text-white mb-2">
              <span
                className="cursor:pointer"
                onClick={() => {
                  scrollToTopAndNavigate("/privacyPolicy");
                }}
              >
                Privacy Policy
              </span>
            </div>
          </div>
        </div>

        <hr className="border-[#A6A6A6] mb-8 hidden sm:flex" />
        <div className="flex flex-row justify-between text-white">
          <div className="w-[40%] hidden sm:flex flex-col lg:flex-row justify-between flex-wrap  white-space: nowrap">
            <div className="mb-2  flex-shrink: 0 ">
              PhoodCry Catering Private Limited. All rights reserved
            </div>
            <div className="mb-2">
              <span
                className="cursor-pointer"
                onClick={() => {
                  scrollToTopAndNavigate("/tAndc");
                }}
                style={{
                  transition: "font-size 0.3s ease",
                  fontSize: "16px", // Adjust the initial font size as needed
                }}
                onMouseOver={(e) => (e.currentTarget.style.fontSize = "1.2em")} // Adjust the enlarged font size as needed
                onMouseOut={(e) => (e.currentTarget.style.fontSize = "16px")}
              >
                Terms Conditions
              </span>
            </div>
            <div className="mb-2">
              <span
                className="cursor-pointer"
                onClick={() => {
                  scrollToTopAndNavigate("/privacyPolicy");
                }}
                style={{
                  transition: "font-size 0.3s ease",
                  fontSize: "16px", // Adjust the initial font size as needed
                }}
                onMouseOver={(e) => (e.currentTarget.style.fontSize = "1.2em")} // Adjust the enlarged font size as needed
                onMouseOut={(e) => (e.currentTarget.style.fontSize = "16px")}
              >
                Privacy Policy
              </span>
            </div>

            {/* <div className="mb-2">Contact us</div> */}
          </div>
                 <div className="mb-2">
              <span
                className="cursor-pointer"
                onClick={() => {
                  scrollToTopAndNavigate("/CancellationPolicy");
                }}
                style={{
                  transition: "font-size 0.3s ease",
                  fontSize: "16px", 
                }}
                onMouseOver={(e) => (e.currentTarget.style.fontSize = "1.2em")} // Adjust the enlarged font size as needed
                onMouseOut={(e) => (e.currentTarget.style.fontSize = "16px")}
              >
                Cancellation & Refund Policy
              </span>
            </div>
          <div className="w-[45%] flex justify-between ">
            <div className="sm:flex hidden flex-col md:flex-row justify-between w-[30%] flex-wrap">
              {/* <img
                src={facebookLogo}
                alt="Facebook Logo"
                className="w-[25px] h-[25px]"
              />
              <img
                src={twitterLogo}
                alt="Twitter Logo"
                className="w-[25px] h-[25px]"
              />
              <img
                src={instaLogo}
                alt="Instagram Logo"
                className="w-[25px] h-[25px]"
              />
              <img
                src={linkedInLogo}
                alt="LinkedIn Logo"
                className="w-[25px] h-[25px]"
              /> */}
            </div>
            <div className="sm:flex sm:flex-wrap hidden flex-row justify-between sm:w-[50%]">
              <img
                src={appleStoreLogo}
                alt="Apple Store Logo"
                className="w-[120px] mb-3"
              />
              <img
                src={playStoreLogo}
                alt="Play Store Logo"
                className="w-[120px] mb-3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
