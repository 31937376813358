import React from "react";
import redDot from "../../../Assets/Images/misc/redDotGradient.svg";
import rightRedGrid from "../../../Assets/Images/misc/rightRedGrid.svg";

const Description = () => {
  return (
    <div className="relative flex text-center sm:text-center justify-center items-center text-[25px] md:text-[30px] text-[#464646] mt-[20px]">
      <img
        src={redDot}
        alt="redDot"
        className="absolute bottom-0 left-[8%] w-[4vw]"
      />
      <img
        src={rightRedGrid}
        alt="img"
        className="absolute right-0 bottom-0 w-[5vw]"
      />
      <div
        className="w-[70vw] "
        style={{
          letterSpacing: 0.2,
          wordWrap: "break-word",
          fontWeight: 400,
          opacity: 0.6,
          // textAlign: "justify",
        }}
      >
        <p>
          At CoolieWale, the well being of our passengers is above everything
          else. We are constantly in pursuit of enhancing our safety measures to
          ensure every Coolies lift is a pleasant and comfortable experience.
        </p>
      </div>
    </div>
  );
};

export default Description;
