import React from "react";
import StyledUnderline from "../../Assets/Images/misc/styledUnderline.svg"
import leftGrayPie from "../../Assets/Images/misc/leftGrayPie.svg"  


const CoolieAgreement = () => {
  return (
    <div>
     
   
    <div className="relative w-full flex mt-[20px] md:mt-[40px] flex-col justify-center text-center items-center sm:text-[28px] text-[24px] font-semibold">
      <img src={leftGrayPie} alt="leftGrayPie" className="absolute top-0 left-0 w-[7vw]" />
      <div className="md:text-[1.5em]">Coolie Agreement</div>
      <div className="w-[20px] sm:w-auto">
        <img src={StyledUnderline} alt="underline" />
      </div>
    </div>

      <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-lg">
<div className="bg-[#EC1C24] mb-8 font-bold border-b-4 border-ec1c24 pb-2 rounded-full text-center">
  <h2 className="text-3xl mb-4">Phoodcy catering Private Limited Coolie Agreement</h2>
</div>

        <div className="p-4">
          <h3 className="text-xl font-bold mb-2">1. Registration and Verification</h3>
          <ul className="list-disc pl-6">
            <li>To register as a coolie on the Services, you must provide the following information:</li>
            <li>
              <ul className="list-disc pl-6">
                <li>Name</li>
                <li>Contact Number</li>
                <li>Address</li>
                <li>UIDAI Aadhar Card Number (verification required)</li>
                <li>Bank Account Details (for receiving payments)</li>
                <li>Badge ID (issued by Indian Railways)</li>
                <li>Coolie Photo (clear headshot)</li>
                <li>Badge Photo (clear picture of your official badge)</li>
              </ul>
            </li>
          </ul>
          <p className="mt-4">Please note:</p>
          <ul className="list-disc pl-6">
            <li>All information provided will be subject to scrutiny by both Phoodcry and Indian Railways.</li>
            <li>You are solely responsible for the accuracy of the information provided.</li>
            <li>A valid UIDAI Aadhar Card Number is mandatory for registration.</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">2. GPS Tracking and Availability</h3>
          <ul className="list-disc pl-6">
            <li>Enabling GPS on your device is mandatory for using the Services.</li>
            <li>GPS helps us locate your availability and efficiently assign you to orders.</li>
            <li>By keeping your GPS enabled, you agree to be tracked while you are actively using the App.</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">3. Order Acceptance and Information</h3>
          <ul className="list-disc pl-6">
            <li>The App or Website will display order details including:</li>
            <li>
              <ul className="list-disc pl-6">
                <li>Pickup point</li>
                <li>Drop point</li>
                <li>Luggage details</li>
                <li>One-Time Password (OTP) for order confirmation</li>
                <li>Passenger contact number</li>
                <li>Fare amount</li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">4. Fare Structure</h3>
          <ul className="list-disc pl-6">
            <li>The fare for each order is determined based on data provided by you and Indian Railway officials.</li>
            <li>The fare structure is transparent and displayed within the App or Website.</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">5. Order Fulfillment</h3>
          <ul className="list-disc pl-6">
            <li>You are responsible for:</li>
            <li>
              <ul className="list-disc pl-6">
                <li>Accepting or declining orders based on your availability.</li>
                <li>Arriving at the pickup point on time.</li>
                <li>Providing courteous and professional service to passengers.</li>
                <li>Completing the order as per the specified details.</li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">6. Payment</h3>
          <ul className="list-disc pl-6">
            <li>Payments for completed orders will be credited to your registered bank account.</li>
            <li>You are responsible for any fees or charges associated with your bank account.</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">7. Code of Conduct</h3>
          <ul className="list-disc pl-6">
            <li>You agree to:</li>
            <li>
              <ul className="list-disc pl-6">
                <li>Maintain a clean and professional appearance while working.</li>
                <li>Uphold ethical conduct and avoid any illegal activity.</li>
                <li>Treat passengers with respect and courtesy.</li>
                <li>Comply with all safety regulations and guidelines set forth by Indian Railways.</li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">8. Termination</h3>
          <p>Phoodcry reserves the right to terminate your agreement and access to the Services for any of the following reasons:</p>
          <ul className="list-disc pl-6">
            <li>Providing false or inaccurate information during registration.</li>
            <li>Engaging in unprofessional or unethical conduct.</li>
            <li>Violating any terms of this Agreement.</li>
            <li>Inactivity on the platform for an extended period.</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">9. Disclaimer</h3>
          <p>The Services are provided "as is" and without warranties of any kind, express or implied. Phoodcry disclaims all liability for any damages arising out of or related to your use of the Services.</p>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">10. Limitation of Liability</h3>
          <p>Phoodcry will not be liable for any indirect, incidental, consequential, or punitive damages arising out of or related to your use of the Services.</p>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">11. Governing Law</h3>
          <p>This Agreement shall be governed by and construed in accordance with the laws of India.</p>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">12. Entire Agreement</h3>
          <p>This Agreement constitutes the entire agreement between you and Phoodcry regarding your use of the Services as a coolie.</p>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">13. Amendments</h3>
          <p>We may amend this Agreement at any time by posting the amended terms on the App and Website. Your continued use of the Services after the posting of any amended terms constitutes your acceptance of the amended Agreement.</p>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">14. Contact Us</h3>
          <ul className="list-disc pl-6">
            <li>If you have any questions about this Agreement, please contact us through the App, Website, contact at 8507992317 or by email at utsav@cooliewale.in</li>
          </ul>
        </div>
      </div>

    </div>
  );
};

export default CoolieAgreement;
