import React from "react";
import Header from "./components/header";
import Navbar from "../shared/Navbar/navbar";
import Footer from "../shared/footer";
import WelcomeMessage from "./components/welcomeMessage";
import Description from "./components/description";
import DownloadButton from "./components/download";
import VisionAndMissionCard from "./components/visionAndmissionCard";
import rightRedGrid from "../../Assets/Images/misc/rightRedGrid.svg";
import visionImage from "../../Assets/Images/feature/vision-2372177_640.webp";
import missionImage from "../../Assets/Images/feature/mission.webp";
import Team from "../TeamPage/team2"


const data = {
  vision:
    "To revolutionize railway travel by seamlessly connecting passengers with reliable coolie services, creating a future where every journey is stress-free and every coolie enjoys enhanced livelihood and support.",
  mission:
    "Empowering passengers with a hassle-free travel experience while fostering dignified employment for coolies through technology, training, and compassionate initiatives.",
};

const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <Header />
      <WelcomeMessage />
      <Description />
      <DownloadButton />
      <div className="flex flex-row flex-wrap mt-[40px] justify-evenly mb-[50px] relative">
        <img src={rightRedGrid} alt="red_grid" className="absolute right-0 top-[50%] w-[4.5vw] hidden lg:flex "/>
        <VisionAndMissionCard title="Vision" description={data.vision} image={visionImage}/>
        <VisionAndMissionCard title="Mission" description={data.mission} image={missionImage}/>
      </div>
      {/* Team  */}
      <div className="flex flex-row flex-wrap mt-[40px] justify-evenly mb-[50px] relative">
        <Team />
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
