import React from "react";
import leftGrayPie from "../../../../../Assets/Images/misc/leftGrayPie.svg";

const Header = () => {
  return (
    <div className="relative w-full flex mt-[40px] md:mt-[80px] flex-col justify-center text-center items-center sm:text-[56px] text-[40px] font-semibold">
      <img
        src={leftGrayPie}
        alt="leftGrayPie"
        className="absolute top-0 left-0 w-[6vw]"
      />
      <div style={{ fontWeight: "bold" }} >Objectives</div>
    </div>
  );
};

export default Header;
