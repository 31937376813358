import React, { useState, useEffect } from 'react';
import axios from 'axios';
import redGradientDot from '../../../Assets/Images/misc/redDotGradient.svg';

function CooliewaleIntro() {
  const [locationData, setLocationData] = useState({ name: null, region: null, country: null });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const Text_cooliewale = [
    (location) => ` Your stress-free travel partner in ${location}: Cooliewale provides trusted coolie services for a smooth and hassle-free journey.`,
    (location) => ` Making travel easier in ${location}: We're Cooliewale, connecting you with reliable porters at the tap of a button.`,
    (location) => ` Need a hand with your luggage in ${location}? Cooliewale is here! We connect you with friendly and dependable coolies.`,
    (location) => ` Traveling through ${location}? Cooliewale's got you covered. Get a coolie to help you with your bags for a pleasant experience.`,
    (location) => ` Safe, reliable, and affordable coolie services in ${location}: That's Cooliewale!`,
    (location) => ` Skip the hassle, get a fair price: Cooliewale offers reliable coolie services in ${location} at transparent and agreed-upon prices.`,
  ];

  useEffect(() => {
    const fetchLocation = async () => {
      setIsLoading(true);
      setError(null);

      try {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(async (position) => {
            const { latitude, longitude } = position.coords;
            const response = await axios.get(`https://geocoder.cooliewale.in/reversegeocoder?lat=${latitude}&lon=${longitude}`);
            setLocationData({
              name: response.data.location.name,
              region: response.data.location.region,
              country: response.data.location.country
            });
          }, (error) => {
            console.error('Error fetching geolocation:', error);
            setError('Geolocation error. Please enable location services.');
          });
        } else {
          setError('Geolocation is not supported by this browser.');
        }
      } catch (error) {
        console.error('Error fetching location:', error);
        setError('Unable to determine your location. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchLocation();
  }, []);

  return (
    <div>
      <div className="relative flex justify-center text-center items-center text-[16px] md:text-[30px] text-[#464646] mt-[20px]">
        <img
          src={redGradientDot}
          alt="redGradientDot"
          className="absolute top-0 left-[4%] w-[6vw] md:hidden lg:flex"
        />
        <div className="w-[80vw]">
          <span>
            <p>
              {isLoading ? (
                'Locating your city...'
              ) : error ? (
                error
              ) : locationData.name ? (
                locationData.name.toLowerCase() === 'varanasi' || locationData.name.toLowerCase() === 'prayagraj' ? (
                  `We are currently serving ${locationData.name}. ${Text_cooliewale[Math.floor(Math.random() * Text_cooliewale.length)](locationData.name)}`
                ) : (
                  Text_cooliewale[Math.floor(Math.random() * Text_cooliewale.length)](`${locationData.name}, ${locationData.region}, ${locationData.country}`)
                )
              ) : (
                'Currently not serving your location.'
              )}
            </p>
          </span>
          <p>
            CoolieWale is a technology-driven transportation Startup that focuses on delivering premium services to Passengers. Its goal is to leverage advanced tech, providing seamless solutions for both passengers and service providers.
          </p>
        </div>
      </div>
      <p className="text-center text-[14px] md:text-[16px] text-[#464646] mt-[10px]">
        {isLoading ? (
          'Finding your location...'
        ) : error ? (
          ''
        ) : locationData.name ? (
          locationData.name.toLowerCase() === 'varanasi' || locationData.name.toLowerCase() === 'prayagraj' ? (
            ''
          ) : (
            `Currently not serving ${locationData.name}, ${locationData.region}, ${locationData.country}`
          )
        ) : (
          ''
        )}
      </p>
    </div>
  );
}

export default CooliewaleIntro;
