import React from "react";  
  
const UserCard = ({ image, name, role, description }) => {  
  return (  
    <div className="max-w-sm mx-auto bg-white rounded-lg shadow-xl overflow-hidden mb-8 transform transition-transform duration-300 hover:scale-105">  
      {image ? (  
        <div className="relative w-full pb-80 bg-gray-200 flex justify-center items-center overflow-hidden rounded-t-lg"> {/* Aspect ratio container */}  
          <img  
            className="absolute inset-0 w-full h-full object-cover" // Use object-cover to maintain aspect ratio and cover the container  
            src={image}  
            alt={`${name}`}  
          />  
          <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-300 hover:opacity-50"></div>  
        </div>  
      ) : null}  
  
      <div className="flex flex-row h-auto">  
        <div className="w-16 flex items-center justify-center bg-black">  
          <svg  
            xmlns="http://www.w3.org/2000/svg"  
            className="w-6 h-6 text-white"  
            fill="none"  
            viewBox="0 0 24 24"  
            stroke="currentColor"  
          >  
            <path  
              strokeLinecap="round"  
              strokeLinejoin="round"  
              strokeWidth={2}  
              d="M17.25 8. 21 12m0 0-3.75 3.75M21 12H3"  
            />  
          </svg>  
        </div>  
        <div className="w-full flex flex-col items-start p-6 transition-colors duration-200 hover:bg-red-500 hover:text-white group description-container">  
          <div className="text-2xl font-bold">{name}</div>  
          <p className="text-gray-500 group-hover:text-white text-sm mb-2">  
            {role}  
          </p>  
          <div className="text-left max-h-16 overflow-hidden transition-all duration-500 ease-in-out group-hover:max-h-96">  
            {description}  
          </div>  
        </div>  
      </div>  
    </div>  
  );  
};  
  
export default UserCard;  
