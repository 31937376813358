import React from "react";
import Header from "./components/header";
import Testimonials from "./components/Testimonials";

const ReviewSection = () => {
  return (
    <div className="md:mt-[40px] mt-[20px] md:p-10 relative">
      {/* Header */}
      <Header />
      {/* Testimonials  */}
      <Testimonials />
    </div>
  );
};

export default ReviewSection;
