import React from "react";

const Header = () => {
  return (
    <div
      className="h-auto flex justify-center items-center text-center text-[24px] sm:text-[45px] lg:text-[56px] text-black"
      style={{ fontWeight: "700", wordWrap: "break-word" }}
    >
      <div className="md:pl-20">Pickup with CoolieWale</div>

    </div>
  );
};

export default Header;
