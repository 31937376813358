import React from "react";
import metro from "../../../../Assets/Images/feature/metro.webp";
import coolie from "../../../../Assets/Images/coolies/coolie2.webp";
import traveller from "../../../../Assets/Images/feature/traveller.webp";
import Img from "../../../../Assets/Images/team/rishab_utsav.webp";
import Coolies from "../../../../Assets/Images/coolies/4503642.webp";
import { useNavigate } from "react-router-dom";

const Main = () => {
  const navigate = useNavigate();
  const scrollToTopAndNavigate = (path) => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    navigate(path); // Navigate to the specified path
  };
  return (
    <div>
      <div className="flex md:flex-row flex-col">
        <div className="flex md:w-1/2 w-full">
          <img src={Coolies} alt="img" className="w-[100%] h-[100%]" />
        </div>
        <div className="md:w-1/2 w-full bg-[#EC1C24] flex flex-col p-[40px]">
          <div className="mb-4 lg:text-[56px] sm:text-[40px] text-[24px] text-white font-bold">
            Know Us Better
          </div>
          <div className="text-white text-[16px] lg:text-[24px] mb-4">
            We are here to revolutionize traditional baggage lifting mechanism
            and infrastructure at railway stations to ensure hassle free
            experiences for passengers
          </div>
          <div
            className="md:mt-10 mt-6 cursor-pointer bg-white text-[18px] text-[#EC1C24] p-4 flex justify-center max-w-[200px]"
            style={{ borderRadius: 40 }}
            onClick={() => {
              scrollToTopAndNavigate("/aboutUs");
            }}
          >
            Read more here
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="w-[30%]">
          <img src={metro} alt="metro img" className="w-[100%] h-[100%]"></img>
        </div>
        <div className="w-[20%]">
          <img
            src={coolie}
            alt="coolie img"
            className="w-[100%] h-[100%]"
          ></img>
        </div>
        <div className="w-[30%]">
          <img
            src={traveller}
            alt="coolie img"
            className="w-[100%] h-[100%]"
          ></img>
        </div>
        <div className="w-[20%]">
          <img src={Img} alt="coolie img" className="w-[100%] h-[100%]"></img>
        </div>
      </div>
    </div>
  );
};

export default Main;
