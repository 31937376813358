import React from "react";
import leftHalfDot from "../../../Assets/Images/misc/leftHalfRedDot.svg";

const WelcomeMessage = () => {
  return (
    <div className="flex flex-col items-center justify-center relative text-[4vw]">
      <img src={leftHalfDot} alt="img" className="absolute right-0 w-[3vw]" />
      <div className="font-bold ">
        <span className="text-black">We are </span>
        <span className="text-[#EC1C24]"> CoolieWale</span>
      </div>
      <div className="font-bold">
        <span className="text-[#EC1C24]" style={{ fontFamily: 'SAMAN___' }}>❝ सafar</span>
        <span className=" text-black " style={{ fontFamily: 'SAMAN___' }}> Ke सaathi ❞</span>
      </div>
    </div>
  );
};

export default WelcomeMessage;
