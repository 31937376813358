import React from "react";
import StyledUnderline from "../../../Assets/Images/misc/styledUnderline.svg"
import leftGrayPie from "../../../Assets/Images/misc/leftGrayPie.svg"
import leftRedGrid from "../../../Assets/Images/misc/leftRedGrid.svg"

const Header = () => {
  return (
    <div className="relative w-full flex mt-[40px] md:mt-[80px] flex-col justify-center text-center items-center sm:text-[56px] text-[24px] font-semibold">
      <img src={leftGrayPie} alt="leftGrayPie" className="absolute top-0 left-0 w-[7vw]" />
      {/* <img src={leftRedGrid} alt="leftGrayPie" className="absolute top-[80px] left-0 w-[2vw] hidden md:flex" /> */}
      
      <div>Contact Us</div>
      <div className="w-[80px] sm:w-auto">
        <img src={StyledUnderline} alt="underline" />
      </div>
    </div>
  );
};

export default Header;
