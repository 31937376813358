import React from "react";

const PrimaryButton = ({ title }) => {
  return (
    <div
      className=" flex justify-center text-center h-auto text-white bg-[#EC1C24] cursor-pointer lg:pl-10 lg:pr-10 p-3 rounded-[24px] font-semibold"
      style={{ boxShadow: "0px 4px 8px rgba(236, 28, 36, 0.50)" }}
    >
      {title}
    </div>
  );
};

export default PrimaryButton;
