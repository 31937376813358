import React from "react";

const Header = () => {
  return (
    <div
      className="h-auto flex justify-center items-center text-[24px] sm:text-[56px] text-black mb-[40px]"
      style={{ fontWeight: "700", wordWrap: "break-word" }}
    >
      <div className="sm:max-w-[650px] max-w-[200px] text-center">Straight from Passenger&#8217;s heart</div>
    </div>
  );
};

export default Header;
