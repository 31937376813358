import React from "react";
import Navbar from "../shared/Navbar/navbar";
import Footer from "../shared/footer";
import StyledUnderline from "../../Assets/Images/misc/styledUnderline.svg"
import leftGrayPie from "../../Assets/Images/misc/leftGrayPie.svg"

const PrivacyPolicy = () => {
        return (
                <div>
      <Navbar />
     
        <div className="relative w-full flex mt-[20px] md:mt-[40px] flex-col justify-center text-center items-center sm:text-[28px] text-[24px] font-semibold">
      <img src={leftGrayPie} alt="leftGrayPie" className="absolute top-0 left-0 w-[7vw]" />
      <div className="md:text-[1.5em]">Privacy Policy</div>
      <div className="w-[20px] sm:w-auto">
        <img src={StyledUnderline} alt="underline" />
      </div>
    </div>
     

      <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-lg">
<div className="bg-[#EC1C24] mb-8 font-bold border-b-4 border-ec1c24 pb-2 rounded-full text-center">
  <h2 className="text-3xl mb-4">Phoodcy catering Private Limited Privacy Policy</h2>
</div>



      <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-lg">
        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            Your privacy is important to us
          </h2>
          <p className="text-gray-700">
            <p> Phoodcry Catering Private Limited</p>
            <p>
              It is CoolieWale's policy to respect your privacy regarding any
              information we may collect while operating our website. This
              Privacy Policy applies to cooliewale.in/ (hereinafter, "us", "we",
              or "cooliewale.in/"). We respect your privacy and are committed to
              protecting personally identifiable information you may provide us
              through the Website. We have adopted this privacy policy ("Privacy
              Policy") to explain what information may be collected on our
              website, how we use this information, and under what circumstances
              we may disclose the information to third parties. This Privacy
              Policy applies only to information we collect through the Website
              and does not apply to our collection of information from other
              sources.
            </p>{" "}
            <br />
            <p>
              {" "}
              This Privacy Policy, together with the Terms of Service posted on
              our website, set forth the general rules and policies governing
              your use of our website. Depending on your activities when
              visiting our website, you may be required to agree to additional
              terms of service.
            </p>
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">Contents</h2>
          <ol className="list-decimal pl-6">
            <li className="text-gray-700 mb-2">
              Personally-Identifying Information
            </li>
            <li className="text-gray-700 mb-2">Security</li>
            <li className="text-gray-700 mb-2">
              Protection of Certain Personally-Identifying Information
            </li>
            <li className="text-gray-700 mb-2">Aggregated Statistics</li>
            <li className="text-gray-700 mb-2">Cookies</li>
            <li className="text-gray-700 mb-2">Privacy Policy Changes</li>
          </ol>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            1. Personally-Identifying Information
          </h2>
          <p className="text-gray-700">
            Certain visitors to CoolieWale's websites choose to interact with
            CoolieWale in ways that require CoolieWale to gather
            personally-identifying information. The amount and type of
            information that CoolieWale gathers depends on the nature of the
            interaction. For example, we ask visitors who leave a comment at
            <a href="https://cooliewale.in/">https://cooliewale.in/</a> to
            provide a username and email address.
          </p>
        </div>

        {/* Repeat similar structures for other sections */}

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">2. Security</h2>
          <p className="text-gray-700">
            The security of your Personal Information is important to us, but
            remember that no method of transmission over the Internet, or method
            of electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Information,
            we cannot guarantee its absolute security.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            3. Protection of Certain Personally-Identifying Information
          </h2>
          <ol className="list-decimal pl-6">
            <li className="text-gray-700 mb-4">
              CoolieWale discloses potentially personally-identifying and
              personally-identifying information only to those of its employees,
              contractors, and affiliated organizations that:
              <ol className="list-disc pl-6">
                <li className="text-gray-700">
                  Need to know that information in order to process it on
                  CoolieWale's behalf or to provide services available at
                  CoolieWale's website.
                </li>
                <li className="text-gray-700">
                  Have agreed not to disclose it to others.
                </li>
              </ol>
            </li>
            <li className="text-gray-700 mb-4">
              Some of those employees, contractors, and affiliated organizations
              may be located outside of your home country; by using CoolieWale's
              website, you consent to the transfer of such information to them.
            </li>
            <li className="text-gray-700 mb-4">
              CoolieWale will not rent or sell potentially
              personally-identifying and personally-identifying information to
              anyone.
            </li>
            <li className="text-gray-700 mb-4">
              Other than to its employees, contractors, and affiliated
              organizations, as described above, CoolieWale discloses
              potentially personally identifying and personally-identifying
              information only in response to a subpoena, court order, or other
              governmental requests, or when CoolieWale believes in good faith
              that disclosure is reasonably necessary to protect the property or
              rights of CoolieWale, third parties or the public at large.
            </li>
            <li className="text-gray-700 mb-4">
              If you are a registered user of https://cooliewale.in/ and have
              supplied your email address, CoolieWale may occasionally send you
              an email to tell you about new features, solicit your feedback, or
              just keep you up to date with what's going on with CoolieWale and
              our products.
            </li>
            <li className="text-gray-700 mb-4">
              We primarily use our blog to communicate this type of information,
              so we expect to keep this type of email to a minimum. If you send
              us a request (for example via a support email or via one of our
              feedback mechanisms), we reserve the right to publish it in order
              to help us clarify or respond to your request or to help us
              support other users.
            </li>
            <li className="text-gray-700">
              CoolieWale takes all measures reasonably necessary to protect
              against the unauthorized access, use, alteration or destruction of
              potentially personally-identifying and personally-identifying
              information.
            </li>
          </ol>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">4. Aggregated Statistics</h2>
          <p className="text-gray-700">
            CoolieWale may collect statistics about the behavior of visitors to
            its website. CoolieWale may display this information publicly or
            provide it to others. However, CoolieWale does not disclose your
            personally-identifying information.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">5. Cookies</h2>
          <p className="text-gray-700">
            <p>
              To enrich and perfect your online experience, CoolieWale uses
              "Cookies", similar technologies and services provided by others to
              display personalized content, appropriate advertising, and store
              your preferences on your computer.A cookie is a string of
              information that a website stores on a visitor's computer, and
              that the visitor's browser provides to the website each time the
              visitor returns. CoolieWale uses cookies to help CoolieWale
              identify and track visitors, their usage of
              https://cooliewale.in/, and their website access preferences.
              CoolieWale visitors who do not wish to have cookies placed on
              their computers should set their browsers to refuse cookies before
              using CoolieWale's websites, with the drawback that certain
              features of CoolieWale's websites may not function properly
              without the aid of cookies.
            </p>
            <p>
              By continuing to navigate our website without changing your cookie
              settings, you hereby acknowledge and agree to CoolieWale's use of
              cookies.
            </p>
          </p>
        </div>
        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">6. Privacy Policy Changes</h2>
          <ol className="list-decimal pl-6">
            <li>
              <p className="text-gray-700">
                Although most changes are likely to be minor, CoolieWale may
                change its Privacy Policy from time to time, and at CoolieWale's
                sole discretion. CoolieWale encourages visitors to frequently
                check this page for any changes to its Privacy Policy. Your
                continued use of this site after any change in this Privacy
                Policy will constitute your acceptance of such change.
              </p>
            </li>
            <li>
              <p className="text-gray-700">
                Nothing in this disclaimer will limit or exclude our or your
                liability for fraud or fraudulent misrepresentation.
              </p>
            </li>
            <li>
              <p className="text-gray-700">
                Limitations and exclusions of liability in any way that is not
                permitted under applicable law.
              </p>
            </li>
            <li>
              <p className="text-gray-700">
                Exclude any of our or your liabilities that may not be excluded
                under applicable law.
              </p>
            </li>
          </ol>
          <p className="text-gray-700">
            The limitations and exclusions of liability set out in this Section
            and elsewhere in this disclaimer are subject to the preceding
            paragraph and govern all liabilities arising under the disclaimer or
            in relation to the subject matter of this disclaimer, including
            liabilities arising in contract, in tort (including negligence), and
            for breach of statutory duty. To the extent that the website and the
            information and services on the website are provided free of charge,
            we will not be liable for any loss or damage of any nature.
          </p>
        </div>
         </div>
      </div>
      <Footer />
    </div>
   
  );
};

export default PrivacyPolicy;