import React from "react";
import { NavLink } from "react-router-dom";
import redDotGradient from "../../../Assets/Images/misc/redDotGradient.svg";

const Download = () => {
  return (
    <div className="flex justify-center items-center mt-[40px] relative">
      <img
        src={redDotGradient}
        alt="dot"
        className="absolute md:right-[15%] right-[5%] w-[6vw]"
      />
      <div
        style={{
          boxShadow: "0px 4px 8px rgba(236, 28, 36, 0.50)",
          fontWeight: "bold",
        }}
        className="cursor-pointer hover:bg-[#EC1C24] hover:text-white rounded-[24px] border-[2px] pl-[20px] pr-[20px] sm:pl-[50px] sm:pr-[50px] pt-[12px] pb-[12px] border-[#EC1C24] text-[15px] md:text-[24px] text-[#EC1C24] text-bold"
      >
        <NavLink to="https://wa.me/919473200007?text=Hi">
        Book Coolie
        </NavLink>
      </div>
    </div>
  );
};

export default Download;
