import React from "react";
import Navbar from "../shared/Navbar/navbar";
import Footer from "../shared/footer";
import StyledUnderline from "../../Assets/Images/misc/styledUnderline.svg"
import leftGrayPie from "../../Assets/Images/misc/leftGrayPie.svg"  


const Terms_Conditions = () => {
  return (
    <div>
    <Navbar />

        <div className="relative w-full flex mt-[20px] md:mt-[40px] flex-col justify-center text-center items-center sm:text-[28px] text-[24px] font-semibold">
      <img src={leftGrayPie} alt="leftGrayPie" className="absolute top-0 left-0 w-[7vw]" />
      <div className="md:text-[1.5em]">Terms & Condition</div>
      <div className="w-[20px] sm:w-auto">
        <img src={StyledUnderline} alt="underline" />
      </div>
    </div>
     

      <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-lg">
<div className="bg-[#EC1C24] mb-8 font-bold border-b-4 border-ec1c24 pb-2 rounded-full text-center">
  <h2 className="text-3xl mb-4">Phoodcy catering Private Limited Terms and Condition</h2>
</div>

    
      <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-lg">
        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">Welcome to CoolieWale</h2>
          <p className="text-gray-700">
            These terms of service outline the rules and regulations for the use
            of CoolieWale’s Website. By accessing this website, we assume you
            accept these terms of service in full.Do not continue to use
            CoolieWale's website if you do not accept all of the terms of
            service stated on this page. The following terminology applies to
            these Terms of Service, Privacy Statement and Disclaimer Notice, and
            any or all Agreements: "Client", "You" and "Your" refers to you, the
            person accessing this website and accepting the Company's terms of
            service. "The Company", "Ourselves", "We", "Our" and "Us", refers to
            our Company. "Party", "Parties", or "Us", refers to both the Client
            and ourselves, or either the Client or ourselves. All terms refer to
            the offer, acceptance, and consideration of payment necessary to
            undertake the process of our assistance to the Client in the most
            appropriate manner, whether by formal meetings of a fixed duration,
            or any other means, for the express purpose of meeting the Client's
            needs in respect of provision of the Company's stated
            services/products, in accordance with and subject to, prevailing law
            of. Any use of the above terminology or other words in the singular,
            plural, capitalization, and/or he/she or they, are taken as
            interchangeable and therefore as referring to same.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">Cookies</h2>
          <p className="text-gray-700">
            We employ the use of cookies. By using CoolieWale's website you
            consent to the use of cookies in accordance with CoolieWale's
            privacy policy. Most modern-day interactive websites use cookies to
            enable us to retrieve user details for each visit. Cookies are used
            in some areas of our site to enable the functionality of this area
            and ease of use for those people visiting. Some of our
            affiliate/advertising partners may also use cookies.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">License</h2>
          <p className="text-gray-700">
            Unless otherwise stated, CoolieWale and/or its licensors own the
            intellectual property rights for all material on CoolieWale. All
            intellectual property rights are reserved. You may view and/or print
            pages from cooliewale.in/ for your own personal use subject to
            restrictions set in these terms of service. You must not: 1.
            Republish material from cooliewale.in/ 2. Sell, rent, or sub-license
            material from cooliewale.in/ 3. Reproduce, duplicate, or copy
            material from cooliewale.in/ Redistribute content from CoolieWale
            (unless content is specifically made for redistribution).
          </p>
        </div>

        {/* Repeat similar structures for other sections */}

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            Use of our CoolieWale Services
          </h2>
          <p className="text-gray-700">
            This is a premium service, the final price is decided by coolie
            based on pickup and drop location (distance, bridges), the quantity
            of bags, the weight of bags, etc. This is an initiative by us to
            help Indian Railway passengers. We are not charging a single fee
            from both coolies or passengers during the pilot run.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">User Comments</h2>
          <ol className="list-decimal pl-6">
            <li>
              <p className="text-gray-700">
                This Agreement shall begin on the date hereof.
              </p>
            </li>
            <li>
              <p className="text-gray-700">
                Certain parts of this website offer the opportunity for users to
                post and exchange opinions, information, material, and data
                ('Comments') in areas of the website. CoolieWale does not
                screen, edit, publish, or review Comments before their
                appearance on the website and Comments do not reflect the views
                or opinions of CoolieWale, its agents, or affiliates. Comments
                reflect the view and opinion of the person who posts such a view
                or opinion. To the extent permitted by applicable laws,
                CoolieWale shall not be responsible or liable for the Comments
                or any loss cost, liability, damages, or expenses caused and or
                suffered as a result of any use of and/or posting of and/or
                appearance of the Comments on this website.
              </p>
            </li>
            <li>
              <p className="text-gray-700">
                CoolieWale reserves the right to monitor all Comments and to
                remove any Comments that it considers in its absolute discretion
                to be inappropriate, offensive, or otherwise in breach of these
                Terms of Service.
              </p>
            </li>
            <li>
              <p className="text-gray-700">
                You warrant and represent that:
                <ol className="list-decimal pl-6">
                  <li>
                    You are entitled to post the Comments on our website and
                    have all necessary licenses and consents to do so.
                  </li>
                  <li>
                    The Comments do not infringe any intellectual property
                    right, including without limitation copyright, patent, or
                    trademark, or other proprietary right of any third party.
                  </li>
                  <li>
                    The Comments do not contain any defamatory, libelous,
                    offensive, indecent, or otherwise unlawful material or
                    material that is an invasion of privacy.
                  </li>
                  <li>
                    The Comments will not be used to solicit or promote business
                    or custom or present commercial activities or unlawful
                    activity.
                  </li>
                </ol>
              </p>
            </li>
            <li>
              <p className="text-gray-700">
                You hereby grant CoolieWale a non-exclusive royalty-free license
                to use, reproduce, edit, and authorize others to use, reproduce,
                and edit any of your Comments in any and all forms, formats, or
                media.
              </p>
            </li>
          </ol>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">Content Liability</h2>
          <p className="text-gray-700">
            We shall have no responsibility or liability for any content
            appearing on your Web site. You agree to indemnify and defend us
            against all claims arising out of or based upon your Website. No
            link(s) may appear on any page on your Web site or within any
            context containing content or materials that may be interpreted as
            libelous, obscene, or criminal, or which infringes, otherwise
            violates, or advocates the infringement or other violation of, any
            third-party rights.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">Reservation of rights</h2>
          <p className="text-gray-700">
            We reserve the right at any time and in its sole discretion to
            request that you remove all links or any particular link to
            ourwebsite. You agree to immediately remove all links to our Website
            upon such request. We also reserve the right to amend these terms of
            service and its linking policy at any time. By continuing to link to
            our Web site, you agree to be bound to and abide by these linking
            terms of service.
          </p>
        </div>
        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">Disclaimer</h2>
          <ol className="list-decimal pl-6">
            <li>
              <p className="text-gray-700">
                To the maximum extent permitted by applicable law, we exclude
                all representations, warranties, and conditions relating to our
                website and the use of this website (including, without
                limitation, any warranties implied by law in respect of
                satisfactory quality, fitness for purpose, and/or the use of
                reasonable care and skill).
              </p>
            </li>
            <li>
              <p className="text-gray-700">
                Nothing in this disclaimer will limit or exclude our or your
                liability for fraud or fraudulent misrepresentation.
              </p>
            </li>
            <li>
              <p className="text-gray-700">
                Limitations and exclusions of liability in any way that is not
                permitted under applicable law.
              </p>
            </li>
            <li>
              <p className="text-gray-700">
                Exclude any of our or your liabilities that may not be excluded
                under applicable law.
              </p>
            </li>
          </ol>
          <p className="text-gray-700">
            The limitations and exclusions of liability set out in this Section
            and elsewhere in this disclaimer are subject to the preceding
            paragraph and govern all liabilities arising under the disclaimer or
            in relation to the subject matter of this disclaimer, including
            liabilities arising in contract, in tort (including negligence), and
            for breach of statutory duty. To the extent that the website and the
            information and services on the website are provided free of charge,
            we will not be liable for any loss or damage of any nature.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            Use of our CoolieWale Services
          </h2>
          <p className="text-gray-700">
            This is a premium service, the final price is decided by coolie
            based on pickup and drop location (distance, bridges), the quantity
            of bags, the weight of bags, etc. This is an initiative by us to
            help Indian Railway passengers. We are not charging a single fee
            from both coolies or passengers during pilot run.
          </p>
        </div>
      </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms_Conditions;
