import React from "react";
import StyledUnderline from "../../../Assets/Images/misc/styledUnderline.svg";
import leftGrayPie from "../../../Assets/Images/misc/leftGrayPie.svg";
import redDot from "../../../Assets/Images/misc/redDotGradient.svg";

const Header = () => {
  return (
    <div className="relative w-full flex mt-[40px] md:mt-[80px] flex-col justify-center text-center items-center sm:text-[56px] text-[40px] font-semibold">
      <img
        src={leftGrayPie}
        alt="leftGrayPie"
        className="absolute top-0 left-0 w-[6vw]"
      />
      <img
        src={redDot}
        alt="redDot"
        className="absolute top-0 right-[4%] w-[4vw]"
      />
      <div>Safety & Security For all</div>
      <div className="w-[80px] sm:w-auto">
        <img src={StyledUnderline} alt="underline" />
      </div>
    </div>
  );
};

export default Header;
