import React from "react";
import { NavLink } from "react-router-dom";
import startup_logo from "../../../Assets/Images/startup_logo.svg";
import TransparentButton from "../Buttons/transparentButton";
import LanguageSelector from "./components/LanguageSelector";
import PrimaryButton from "../Buttons/primaryButton";
import RightDrawer from "../Drawers/rightDrawer";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-row w-full h-auto mt-4 justify-between m-aut items-center">
      {/* Cooliwale logo here */}
      <div className="w-[15%] flex overflow-hidden ">
        <img
          src={startup_logo}
          alt="coolieWale-logo"
          className="max-h-[70px] pl-[20%] rounded-full p-2"
        />
      </div>
      {/* Navigation Menu and download options with download options */}
      <div className="w-[75%] hidden md:flex flex-row-reverse justify-between items-center h-full ">
        {/* Download options here */}
        <div className="w-[20%] flex flex-row-reverse ">
          <div className="pr-[20%] text-[15px]">
            <NavLink to="https://wa.me/919473200007?text=Hi">
              <PrimaryButton title="Book Coolie" />
            </NavLink>
          </div>
        </div>

        {/* Language switch here */}
        {/* <div className="w-[15%] lg:text-[22px] text-[15px]">
          <LanguageSelector />
        </div> */}

        {/* Buttons for navigating on pages */}
        <div className="flex flex-row justify-between lg:text-[22px] text-[15px] w-[50%]">
          <div
            onClick={() => {
              navigate("/");
            }}
          >
            <TransparentButton title="Home" />
          </div>
          <div
            onClick={() => {
              navigate("/aboutUs");
            }}
          >
            <TransparentButton title="AboutUs" />
          </div>
          <div
            onClick={() => {
              navigate("/safety");
            }}
          >
            <TransparentButton title="Safety" />
          </div>
          <div
            onClick={() => {
              navigate("/tAndc");
            }}
          >
            <TransparentButton title="Terms" />
          </div>
                    <div
            onClick={() => {
              navigate("/privacyPolicy");
            }}
          >
          
            <TransparentButton title="Privacy" />
          </div>
                                 <div
            onClick={() => {
              navigate("/pricingpolicy");
            }}
          >
          
            <TransparentButton title="Pricing" />
          </div>
                       <div
            onClick={() => {
              navigate("/CancellationPolicy");
            }}
          >
          
            <TransparentButton title="Cancellation" />
          </div>

          <div
            onClick={() => {
              navigate("/contactUs");
            }}
          >

            <TransparentButton title="ContactUs" />
          </div>
        </div>
      </div>
      <div className="md:hidden pr-[3%]">
        <RightDrawer />
      </div>
    </div>
  );
};

export default Navbar;
