import React from "react";

const CoolieAppDescription = () => {
  return (
    <div>
      <div className="flex justify-center text-center bg-[#EC1C24] mt-2">
        <div className="text-white p-4 flex flex-col items-start">
          <h2 className="text-5xl font-bold mb-2 border-b-4 border-ec1c24 pb-2">
            CoolieinCoolieWale App
          </h2>
          <p className="text-lg font-semibold">Phoodcry Catering Private Limited</p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-lg">
        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            CoolieinCoolieWale: Your All-in-One Earning App for Indian Railways Coolies
          </h2>
          <p className="text-gray-700">
            CoolieinCoolieWale is your comprehensive solution for managing orders, tracking earnings, and enhancing your work efficiency as a coolie at Indian Railways stations. This user-friendly app empowers you with tools to streamline your workday and maximize your earning potential.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            Simplified Registration
          </h2>
          <ul className="list-disc pl-6">
            <li className="text-gray-700 mb-4">
              Effortless Sign-Up: Register using your mobile number, name, profile picture, and official badge ID.
            </li>
            <li className="text-gray-700 mb-4">
              Secure Banking: Link your bank account details for seamless money transfers.
            </li>
            <li className="text-gray-700">
              Trusted Verification: Get verified by both Phoodcry and Indian Railways for a trusted work environment.
            </li>
          </ul>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            Seamless Order Management
          </h2>
          <ul className="list-disc pl-6">
            <li className="text-gray-700 mb-4">
              Real-Time Order Visibility: See available orders near you with detailed information like pickup and drop points, station name, luggage details, date, and time.
            </li>
            <li className="text-gray-700 mb-4">
              Flexible Acceptance: Accept orders based on your availability and preferences with a single tap.
            </li>
            <li className="text-gray-700 mb-4">
              Future Enhancements: (Coming Soon!) Propose price adjustments and manage order modifications for greater control.
            </li>
          </ul>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            Enhanced Work Efficiency
          </h2>
          <ul className="list-disc pl-6">
            <li className="text-gray-700 mb-4">
              OTP Verification: Start each order securely with a one-time password (OTP) received from the customer.
            </li>
            <li className="text-gray-700 mb-4">
              In-App Communication: Directly contact passengers through the app for any clarifications (future release).
            </li>
            <li className="text-gray-700 mb-4">
              Effortless Tracking: Monitor completed jobs and earnings history within the app.
            </li>
          </ul>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            Transparent Earnings & Support
          </h2>
          <ul className="list-disc pl-6">
            <li className="text-gray-700 mb-4">
              Guaranteed Payments: Receive order payments directly deposited into your bank account within T+1 business days.
            </li>
            <li className="text-gray-700">
              Discrepancy Reporting: Report issues with luggage details, pickup/drop points, or fares directly through the app for prompt resolution.
            </li>
          </ul>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            Safety and Security
          </h2>
          <ul className="list-disc pl-6">
            <li className="text-gray-700 mb-4">
              SOS Button: Access immediate assistance for emergencies like health concerns, medical needs, or theft.
            </li>
            <li className="text-gray-700">
              Verified Platform: Rest assured, CoolieinCoolieWale ensures security with dual verification for coolies and passengers.
            </li>
          </ul>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            Active Status Management
          </h2>
          <ul className="list-disc pl-6">
            <li className="text-gray-700 mb-4">
              Intelligent Tracking: The app intelligently tracks your active status to match you with relevant orders based on location and availability.
            </li>
            <li className="text-gray-700">
              Stay Connected: Maximize your earning potential with continuous connectivity and efficient order management.
            </li>
          </ul>
        </div>

        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4">
            Download CoolieinCoolieWale Today!
          </h2>
          <ul className="list-disc pl-6">
            <li className="text-gray-700 mb-4">
              Find more work opportunities and boost your income with CoolieinCoolieWale.
            </li>
            <li className="text-gray-700 mb-4">
              Enjoy flexible work schedules that suit your needs.
            </li>
            <li className="text-gray-700 mb-4">
              Benefit from clear communication channels and timely payments.
            </li>
            <li className="text-gray-700">
              Work in a safe and secure environment with dedicated support from CoolieinCoolieWale.
            </li>
          </ul>
        </div>

        <p className="text-gray-700">
          CoolieinCoolieWale: Your Path to a Smoother, More Rewarding Work Experience!
        </p>

        <div className="mt-8">
          <h2 className="text-3xl font-bold mb-4">
            Please Note:
          </h2>
          <ul className="list-disc pl-6">
            <li className="text-gray-700">
              This app is exclusively for registered coolies working with Phoodcry at Indian Railways stations.
            </li>
            <li className="text-gray-700">
              We are constantly working to improve the app. Features like in-app communication and order modification are planned for future releases.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CoolieAppDescription;
