import React from "react";
import Navbar from "../shared/Navbar/navbar";
import Footer from "../shared/footer";
import Header from "./components/header";
import Description from "./components/description";
import SafetySection from "./components/safetySection";
import CoolieSafetySection from "./components/coolieSafetySection";

const Safety = () => {
  return (
    <div>
      <Navbar />
      <Header />
      <Description />
      <SafetySection />
      <CoolieSafetySection />
      <Footer />
    </div>
  );
};

export default Safety;
