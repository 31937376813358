import React from "react";
import Navbar from "../shared/Navbar/navbar";
import Footer from "../shared/footer";
import StyledUnderline from "../../Assets/Images/misc/styledUnderline.svg"
import leftGrayPie from "../../Assets/Images/misc/leftGrayPie.svg"  


const Cancellation = () => {
  return (
    <div>
      <Navbar />
           <div className="relative w-full flex mt-[20px] md:mt-[40px] flex-col justify-center text-center items-center sm:text-[28px] text-[24px] font-semibold">
      <img src={leftGrayPie} alt="leftGrayPie" className="absolute top-0 left-0 w-[7vw]" />
      <div className="md:text-[1.5em]">Cancellation & Refund Policy</div>
      <div className="w-[20px] sm:w-auto">
        <img src={StyledUnderline} alt="underline" />
      </div>
    </div>
     

      <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-lg">
<div className="bg-[#EC1C24] mb-8 font-bold border-b-4 border-ec1c24 pb-2 rounded-full text-center">
  <h2 className="text-3xl mb-4">Phoodcy Catering Private Limited Cancellation and Refund Policy</h2>
</div>


        <div className="p-4">
          <h3 className="text-xl font-bold mb-2">1. Order Cancellation and Charges</h3>
          <ul className="list-disc pl-6">
            <li>You (Passenger) have the right to cancel your order before the assigned coolie reaches the pickup point.</li>
            <li>The following cancellation charges will apply based on the time of cancellation before the selected time:
              <ul className="list-disc pl-6">
                <li>0 to 40 Minutes: Cancellation charges of up to Rs. 50 plus service charges.</li>
                <li>41 Minutes to 1 Hour: Cancellation charges of up to Rs. 30 plus service charges.</li>
                <li>More than 1 Hour: Refund after deduction of Rs. 20 plus service charges.</li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">2. Cancellation After Selected Time</h3>
          <p>Cancellations made after the selected time will not be eligible for a refund and will incur waiting charges.</p>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">3. Waiting Charges</h3>
          <ul className="list-disc pl-6">
            <li>A waiting charge of Rs. 50 per hour will be applied if you cancel your order after the coolie arrives at the pickup point and you are not present or delay the service.</li>
            <li>Waiting charges are on top of any applicable cancellation charges mentioned above.</li>
            <li>These charges are to compensate the coolie for their time and are similar to charges levied by Indian Railways.</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">4. Refund Process</h3>
          <ul className="list-disc pl-6">
            <li>In case of a valid cancellation within the specified slabs mentioned in section 1, a refund will be initiated within T+2 business days (excluding Sundays and national holidays) after the deduction of applicable cancellation charges.</li>
            <li>No refunds will be processed for cancellations after the selected time.</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">5. Refund Method</h3>
          <p>Refunds will be processed through the original payment method used for the order.</p>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">6. Indian Railway and Coolie Waiting Charges</h3>
          <p>Please note that Indian Railways and coolies may levy additional waiting charges if their charges differ from the ones mentioned above.These charges are determined and levied by the respective authorities and are beyond Phoodcy Catering Private Limited control.</p>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">7. Transparency in Pricing</h3>
          <ul className="list-disc pl-6">
            <li>The fare for each order is clearly displayed on the App or Website before you confirm your booking.</li>
            <li>The fare structure considers data provided by coolies and Indian Railway officials.</li>
            <li>By placing an order, you acknowledge and agree to the displayed fare, including any applicable cancellation or waiting charges.</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">8. Order Accuracy</h3>
          <ul className="list-disc pl-6">
            <li>We encourage you to carefully review the order details (pickup point, drop point, luggage details, fare amount) before confirming your booking.</li>
            <li>Phoodcry Catering Private Limited is not responsible for refunds or cancellations due to inaccurate information provided during order placement.</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">Please Note:</h3>
          <ul className="list-disc pl-6">
            <li>This Policy is subject to change at any time. We will post any changes on the App and Website.</li>
            <li>Your continued use of the Services after the posting of any revised Policy constitutes your acceptance of the changes.</li>
            <li>If you have any questions regarding this Policy, please contact us through the App, Website, contact at <a href="tel:+918507992317">+91 8507992317</a> or by email at <a href="mailto:utsav@cooliewale.in">utsav@cooliewale.in</a></li>

  </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Cancellation;
