import HomePage from "./Pages/HomePage/homePage";
import ContactUs from "./Pages/ContactUSPage/contactUs";
import AboutUs from "./Pages/AboutUsPage/aboutUs";
import Safety from "./Pages/SafetyPage/safety";
import { Routes, Route } from "react-router-dom";
import Terms_Conditions from "./Pages/Terms_Conditions/terms_conditionsPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy/privacyPolicy";
import TeamPage from "./Pages/TeamPage/main";
import AppDescription from "./Pages/AppDescription/AppDescription";
import UserAgreement from "./Pages/UserAgreement/UserAgreement";
import RefundCancellationPolicy from "./Pages/Cancellation/Cancellation";
import PricingPolicy from "./Pages/Pricing/Pricing";
import "./App.css";

function App() {
  return (
    <Routes>
      <Route path="/" Component={HomePage} />
      <Route path="/contactUS" Component={ContactUs} />
      <Route path="/aboutUs" Component={AboutUs} />
      <Route path="/safety" Component={Safety} />
      <Route path="/tandc" Component={Terms_Conditions} />
      <Route path="/privacyPolicy" Component={PrivacyPolicy} />
      <Route path="/team" Component={TeamPage} />
          <Route path="/appdescription" Component={AppDescription} />
        <Route path="/CancellationPolicy" Component={RefundCancellationPolicy} />
         <Route path="/useragreement" Component={UserAgreement} />
            <Route path="/pricingpolicy" Component={PricingPolicy} />
    </Routes>
  );
}

export default App;
