import React from "react";
import Navbar from "../shared/Navbar/navbar";
import Footer from "../shared/footer";
import StyledUnderline from "../../Assets/Images/misc/styledUnderline.svg";
import leftGrayPie from "../../Assets/Images/misc/leftGrayPie.svg";
import ratecard from "../../Assets/Images/ratecard/ratecard.webp";
import "./table.css";

const PricingPolicy = () => {
  return (
    <div>
      <Navbar />
      <div className="relative w-full flex mt-[20px] md:mt-[40px] flex-col justify-center text-center items-center sm:text-[28px] text-[24px] font-semibold">
        <img src={leftGrayPie} alt="leftGrayPie" className="absolute top-0 left-0 w-[7vw]" />
        <div className="md:text-[1.5em]">Pricing Policy</div>
        <div className="w-[20px] sm:w-auto">
          <img src={StyledUnderline} alt="underline" />
        </div>
      </div>

      <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-lg">
        <div className="bg-[#EC1C24] mb-8 font-bold border-b-4 border-ec1c24 pb-2 rounded-full text-center">
          <h2 className="text-3xl mb-4">Phoodcy Catering Private Limited Pricing Policy</h2>
        </div>

        <div className="p-4">
          <h3 className="text-xl font-bold mb-2">1. Pricing Determination</h3>
          <ul className="list-disc pl-6">
            <li>CoolieWale (Phoodcy Catering Private Limited) acts as a facilitator for booking porters (coolies) at Indian railway stations. Our pricing is based on rates determined by Indian Railways.</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">2. Service Areas</h3>
          <p>We currently offer our services at:</p>
          <ul className="list-disc pl-6">
            <li>Banaras Station (Manduadih) (BSBS)</li>
            <li>Prayagraj Rambag (PRRB)</li>
            <li>Prayagraj Jhunsi (JI)</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">3. Pricing</h3>
         
           <img src={ratecard} alt="Rate Card Image" className="table-img mt-4"/>
     <p className="mb-8 p-4 text-center font-bold">Base Fare</p>
          <table className="w-full border-collapse border border-gray-200">
            <thead>
              <tr>
                <th className="border border-gray-300 px-4 py-2">Section</th>
                <th className="border border-gray-300 px-4 py-2">Sub-Section</th>
                <th className="border border-gray-300 px-4 py-2">Description</th>
                <th className="border border-gray-300 px-4 py-2">Charge (Rs.)</th>
                <th className="border border-gray-300 px-4 py-2">Waiting Charge</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-300 px-4 py-2">Luggage Carrying</td>
                <td className="border border-gray-300 px-4 py-2">Distance with Weight</td>
                <td className="border border-gray-300 px-4 py-2">Per Round Trip</td>
                <td className="border border-gray-300 px-4 py-2">Heavy Duty</td>
                <td className="border border-gray-300 px-4 py-2">Rate with Weight</td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">Luggage Carrying</td>
                <td className="border border-gray-300 px-4 py-2"></td>
                <td className="border border-gray-300 px-4 py-2">For carrying 37 kg of luggage from outside to any platform or vice versa</td>
                <td className="border border-gray-300 px-4 py-2">50</td>
                <td className="border border-gray-300 px-4 py-2">First half hour at the fixed rate</td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">Luggage Carrying</td>
                <td className="border border-gray-300 px-4 py-2"></td>
                <td className="border border-gray-300 px-4 py-2">If waiting more than half hour</td>
                <td className="border border-gray-300 px-4 py-2"></td>
                <td className="border border-gray-300 px-4 py-2">By mutual agreement</td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">Trolley/Wheelchair/Stretcher</td>
                <td className="border border-gray-300 px-4 py-2">Small Trolley</td>
                <td className="border border-gray-300 px-4 py-2">Up to 2 quintals, one porter</td>
                <td className="border border-gray-300 px-4 py-2">100</td>
                <td className="border border-gray-300 px-4 py-2">First half hour at the fixed rate</td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">Trolley/Wheelchair/Stretcher</td>
                <td className="border border-gray-300 px-4 py-2"></td>
                <td className="border border-gray-300 px-4 py-2">If waiting more than half hour</td>
                <td className="border border-gray-300 px-4 py-2"></td>
                <td className="border border-gray-300 px-4 py-2">By mutual agreement</td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">Trolley/Wheelchair/Stretcher</td>
                <td className="border border-gray-300 px-4 py-2">Large Trolley</td>
                <td className="border border-gray-300 px-4 py-2">More than 2 quintals, up to 4 quintals, two porters</td>
                <td className="border border-gray-300 px-4 py-2">150</td>
                <td className="border border-gray-300 px-4 py-2"></td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">Trolley/Wheelchair/Stretcher</td>
                <td className="border border-gray-300 px-4 py-2">Wheelchair/Stretcher</td>
                <td className="border border-gray-300 px-4 py-2">For carrying a sick person</td>
                <td className="border border-gray-300 px-4 py-2">150</td>
                <td className="border border-gray-300 px-4 py-2"></td>
              </tr>
            </tbody>
          </table>
            </div>
        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">4. Other Factors Affecting Price</h3>
          <ul className="list-disc pl-6">
            <li>Baggage weight, dimension, and quantity</li>
            <li>Number of coolies required to complete the luggage lift service</li>
            <li>Waiting time</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">Please Note:</h3>
          <ul className="list-disc pl-6">
            <li>This Policy is subject to change at any time. We will post any changes on the App and Website.</li>
            <li>Your continued use of the Services after the posting of any revised Policy constitutes your acceptance of the changes.</li>
            <li>If you have any questions regarding this Policy, please contact us through the App, Website, contact at <a href="tel:+918507992317">+91 8507992317</a> or by email at <a href="mailto:utsav@cooliewale.in">utsav@cooliewale.in</a></li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PricingPolicy;
