import React from "react";
import Navbar from "../shared/Navbar/navbar";
import Header from "./components/header";
import UserDetailsForm from "./components/userDetailsForm";
import ContactInfo from "./components/officeDetails";
import gradientDot from "../../Assets/Images/misc/redDotGradient.svg";
import redPie from "../../Assets/Images/misc/redPie.svg";
import Footer from "../shared/footer"


const ContactUs = () => {
  return (
    <div className="relative">
      {/* decorating images  */}
      {/* <img
        src={rightRedGrid}
        alt="rightRedGrid"
        className="absolute top-[700px] right-0 w-[7vw]"
      /> */}
      <div className="absolute top-[105px]  flex justify-center right-[24%]">
        <div
          className=" w-[4vw] h-[4vw] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          style={{
            backgroundImage: `url(${redPie})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <img src={gradientDot} alt="gradientDots" className="w-[3vw]  " />
      </div>

      <Navbar />
      <Header />
      <div className="flex md:flex-row flex-col h-auto w-auto m-auto justify-center">
        {/* User Details submit form */}
        <div className="md:w-[70%]">
          <UserDetailsForm />
        </div>
      </div>
      <div className="bottom-0 relative">
      <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
