import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import redGradientDot from "../../../Assets/Images/misc/redDotGradient.svg";
import { backendUrl } from "../../../backendUrl";

const UserDetailsForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    role: "",
    comment: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await fetch(`${backendUrl}/submitContactUs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (res.status === 500) {
        window.alert(
          "Please try after sometimes or email us on contact@cooliewale.in"
        );
        console.log("Server Error");
      } else if (res.status === 429) {
        window.alert(
          "Please try after one minute as you already filled form 2 times."
        );
        console.log("Rate Limited");
      } else {
        window.alert("Form Submitted Successfully");
        console.log("Form Submitted Successfully");
        navigate("/");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="mt-8 mx-auto pl-[10%] pr-[5%] mb-[50px] relative ">
      <img
        src={redGradientDot}
        alt="red dot"
        className="absolute left-0 top-[25%]  w-[4vw]"
      />
      <img
        src={redGradientDot}
        alt="red dot"
        className="absolute left-[1%] top-[80%]  w-[4vw]"
      />

      <form onSubmit={handleSubmit} className="mx-auto space-y-8">
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-black sm:text-[25px] lg:text-[32px] font-semibold mb-2"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-2 bg-[#F7F7F7] rounded lg:h-[70px]"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-black sm:text-[25px] lg:text-[32px] font-semibold mb-2"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2  bg-[#F7F7F7]  rounded lg:h-[70px]"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="mobile"
            className="block text-black sm:text-[25px] lg:text-[32px] font-semibold mb-2"
          >
            Mobile No.
          </label>
          <input
            type="tel"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            className="w-full p-2 bg-[#F7F7F7] rounded lg:h-[70px]"
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="role"
            className="block text-black sm:text-[25px] lg:text-[32px] font-semibold mb-2"
          >
            You are
          </label>
          <select
            id="role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            className="w-full p-2 bg-[#F7F7F7] rounded lg:h-[70px]"
            required
          >
            <option value="" disabled>
              Select an option
            </option>
            <option value="Coolie">Coolie</option>
            <option value="Passenger">Passenger</option>
          </select>
        </div>

        <div className="mb-4">
          <label
            htmlFor="comment"
            className="block text-black sm:text-[25px] lg:text-[32px] font-semibold mb-2"
          >
            Comment
          </label>
          <textarea
            id="comment"
            name="comment"
            value={formData.comment}
            onChange={handleChange}
            className="w-full p-2 bg-[#F7F7F7] rounded"
            required
          />
        </div>

        <div className="text-center">
          <button
            type="submit"
            className="bg-[#EC1C24] text-white py-2 px-4 rounded w-[50%] md:text-[24px]"
            style={{ boxShadow: "0px 4px 8px rgba(236, 28, 36, 0.50)" }}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserDetailsForm;
