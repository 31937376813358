import React from "react";
import StyledUnderline from "../../../Assets/Images/misc/styledUnderline.svg"
import leftGrayPie from "../../../Assets/Images/misc/leftGrayPie.svg"

const Header = () => {
  return (
    <div className="relative w-full flex mt-[20px] md:mt-[40px] flex-col justify-center text-center items-center sm:text-[28px] text-[24px] font-semibold">
      <img src={leftGrayPie} alt="leftGrayPie" className="absolute top-0 left-0 w-[7vw]" />
      <div className="md:text-[1.5em]">About us</div>
      <div className="w-[20px] sm:w-auto">
        <img src={StyledUnderline} alt="underline" />
      </div>
    </div>
  );
};

export default Header;
