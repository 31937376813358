import React from "react";

const UserReviewCard = ({ user }) => {
  return (
    <div
      className="md:h-[340px] h-auto w-auto p-[18px] pb-[20px] pt-[40px]  flex flex-col rounded-[20px] m-4"
      style={{ boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.05)" }}
    >
      <div className="h-[20%] flex flex-row mb-5">
        {/* User's pic */}
        <div className="w-20% mr-2">
          <div className="w-[70px] h-[70px] rounded-full ">
            <img
              src={user.pic}
              alt="User Pic"
              className="h-full w-full rounded-full "
            />
          </div>
        </div>
        {/* User's name and profession */}
        <div className="flex flex-col justify-center ml-3">
          <div className="text-[18px] font-semibold">{user.name}</div>
          <div className="text-[14px]">{user.profession}</div>
        </div>
      </div>
      {/* User's review */}
      <div
        className="h-[80%] pt-5 text-[#464646] text-md"
        style={{ fontWeight: "400" }}
      >
        {user.review}
      </div>
    </div>
  );
};

export default UserReviewCard;
