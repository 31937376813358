import React from "react";
import dottedRedCircle from "../../../Assets/Images/misc/dottedRedCircle.svg";

const Card2 = ({ id, title, description, list,image }) => {
  return (
    <div className="flex md:p-10 p-5 relative">
      <div className="absolute top-[2%] left-[5%] flex justify-center items-center">
        <div
          className="md:w-[90px] md:h-[90px] w-[80px] h-[80px] absolute"
          style={{
            backgroundImage: `url(${dottedRedCircle})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <div className="text-[#EC1C24] text-[45px]" style={{ fontWeight: 700 }}>
          {id}
        </div>
      </div>
      <div
        className="flex flex-col md:flex-row border justify-center items-center p-4"
        style={{
          boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.07)",
          borderRadius: 20,
        }}
      >
        <div className="mt-[40px] mb-3 w-[50%] justify-center flex items-center">
          <div className="w-[120px] h-[120px] rounded-full flex">
            <img src={image} alt="User Pic" className="h-full" />
          </div>
        </div>
        <div className="flex flex-col mb-4">
          <div
            className="md:text-[40px] text-[30px]"
            style={{ fontWeight: 600 }}
          >
            {title}
          </div>
          <div
            className="mb-8"
            style={{
              color: "#58595B",
              fontSize: 20,
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            {description}
          </div>
          <div className="mt-4">
            {list.map((item) => (
              <div className="flex flex-row items-center mb-2">
                <div className="w-[10px] h-[10px] rounded-full bg-[#EC1C24] mr-3"></div>
                <div className="text-[#58595B] md:text-[20px]">{item}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card2;
