import React from "react";
import Card from "../../../../shared/Cards/ImageDescriptionCard";
import PickupImg1 from "../../../../../Assets/Images/coolies/PickupImg1.webp";
import PickupImg2 from "../../../../../Assets/Images/coolies/PickupImg2.webp";

const SupportDetails = () => {
  const description1 = "Get this service according to your trolley load";
  const description2 =
    "Emphathetic support for elderly and disabled passengers.";

  return (
    <div className="mt-[20px] md:mt-[40px] ml-[5%] mr-[5%] flex md:flex-row flex-col justify-center p-4">
      <div className="md:mr-10 md:mb-0 mb-[40px]">
        <Card image={PickupImg1} description={description1} overlayColor="black"/>
      </div>
      <div>
        <Card image={PickupImg2} description={description2} overlayColor="red"/>
      </div>
    </div>
  );
};

export default SupportDetails;
