import React from "react";
import Navbar from "../shared/Navbar/navbar";
import Footer from "../shared/footer";
import StyledUnderline from "../../Assets/Images/misc/styledUnderline.svg"
import leftGrayPie from "../../Assets/Images/misc/leftGrayPie.svg"

import PassengerAgreement from "./PassengerAgreement";
import CoolieAgreement from "./CoolieAgreement";

const UserAgreement = () => {
  return (
    <div>
      <Navbar />
     <div className="relative w-full flex mt-[20px] md:mt-[40px] flex-col justify-center text-center items-center sm:text-[28px] text-[24px] font-semibold">
      <img src={leftGrayPie} alt="leftGrayPie" className="absolute top-0 left-0 w-[7vw]" />
      <div className="md:text-[1.5em]">User Agreement</div>
      <div className="w-[20px] sm:w-auto">
        <img src={StyledUnderline} alt="underline" />
      </div>
    </div>

      <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-lg">
        <div className="mb-8">
         
        </div>

        <div id="passenger">
          <PassengerAgreement />
        </div>

        <div id="coolie" className="mt-12">
          <CoolieAgreement />
        </div>

        <div className="mt-12">
          <p className="text-lg">
            <strong>Navigation:</strong> Jump to{" "}
            <a href="#passenger" className="text-blue-500 hover:underline">
              Passenger Agreement
            </a>{" "}
            or{" "}
            <a href="#coolie" className="text-blue-500 hover:underline">
              Coolie Agreement
            </a>
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default UserAgreement;
