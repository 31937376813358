import React from "react";
import StyledUnderline from "../../Assets/Images/misc/styledUnderline.svg"
import leftGrayPie from "../../Assets/Images/misc/leftGrayPie.svg"  


const PassengerAgreement = () => {
  return (
    <div>
   
     
        <div className="relative w-full flex mt-[20px] md:mt-[40px] flex-col justify-center text-center items-center sm:text-[28px] text-[24px] font-semibold">
      <img src={leftGrayPie} alt="leftGrayPie" className="absolute top-0 left-0 w-[7vw]" />
      <div className="md:text-[1.5em]">Passenger Agreement</div>
      <div className="w-[20px] sm:w-auto">
        <img src={StyledUnderline} alt="underline" />
      </div>
    </div>
     

      <div className="max-w-7xl mx-auto p-4 bg-white rounded-lg shadow-lg">
<div className="bg-[#EC1C24] mb-8 font-bold border-b-4 border-ec1c24 pb-2 rounded-full text-center">
  <h2 className="text-3xl mb-4">Phoodcy catering Private Limited Passenger Agreement</h2>
</div>



        <div className="p-4">
          <h3 className="text-xl font-bold mb-2">1. User Data Collection and Use</h3>
          <ul className="list-disc pl-6">
            <li>We collect the following user data:</li>
            <li>
              <ul className="list-disc pl-6">
                <li>Name</li>
                <li>Contact Number</li>
                <li>GPS Location (optional)</li>
              </ul>
            </li>
            <li>We collect your name and contact number for registration, login, and order placement purposes. Your GPS location (if enabled) helps us provide estimated delivery times for your orders. We will not share your data with any third party without your consent, except as necessary to fulfill your orders or comply with legal requirements.</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">2. Account Registration and Login</h3>
          <ul className="list-disc pl-6">
            <li>You will need a valid contact number to register and login to the Services.</li>
            <li>You are responsible for maintaining the confidentiality of your login credentials and for all activities that occur under your account.</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">3. Order Placement</h3>
          <ul className="list-disc pl-6">
            <li>To place an order, you must accurately provide the following information:</li>
            <li>
              <ul className="list-disc pl-6">
                <li>Luggage details</li>
                <li>Pickup point</li>
                <li>Drop point</li>
                <li>Station</li>
                <li>Date and Time</li>
              </ul>
            </li>
            <li>We recommend enabling GPS location for a more accurate time estimate (subject to availability of coolies).</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">4. Order Modification and Cancellation</h3>
          <ul className="list-disc pl-6">
            <li>You can request order modifications or cancellations through the App, Website, or by contacting our Customer Care.</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">5. Booking Availability and Timing Restrictions</h3>
          <ul className="list-disc pl-6">
            <li>Bookings may be denied if coolies are unavailable at your requested time.</li>
            <li>Our Services are generally available between 6:00 AM and 11:00 PM. Orders placed between 11:00 PM and 6:00 AM may be subject to late-night charges.</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">6. User Responsibilities</h3>
          <ul className="list-disc pl-6">
            <li>You are responsible for:</li>
            <li>
              <ul className="list-disc pl-6">
                <li>Providing accurate information during registration, order placement, and any modifications.</li>
                <li>Maintaining the confidentiality of your login credentials.</li>
                <li>Complying with all applicable laws and regulations while using the Services.</li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">7. Right to Deny Service</h3>
          <ul className="list-disc pl-6">
            <li>We reserve the right to deny service to any user who violates the terms of this Agreement or who we believe poses a risk to our business or other users.</li>
          </ul>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">8. Disclaimer</h3>
          <p>The Services are provided "as is" and without warranties of any kind, express or implied. Phoodcry disclaims all liability for any damages arising out of or related to your use of the Services.</p>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">9. Limitation of Liability</h3>
          <p>Phoodcry will not be liable for any indirect, incidental, consequential, or punitive damages arising out of or related to your use of the Services.</p>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">10. Governing Law</h3>
          <p>This Agreement shall be governed by and construed in accordance with the laws of India.</p>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">11. Entire Agreement</h3>
          <p>This Agreement constitutes the entire agreement between you and Phoodcry regarding your use of the Services.</p>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">12. Amendments</h3>
          <p>We may amend this Agreement at any time by posting the amended terms on the App and Website. Your continued use of the Services after the posting of any amended terms constitutes your acceptance of the amended Agreement.</p>
        </div>

        <div className="mb-8 p-4">
          <h3 className="text-xl font-bold mb-2">13. Contact Us</h3>
          <ul className="list-disc pl-6">
            <li>If you have any questions about this Agreement, please contact us through the App, Website, contact at 8507992317 or by email at utsav@cooliewale.in</li>
          </ul>
        </div>
      </div>

    </div>
  );
};

export default PassengerAgreement;
